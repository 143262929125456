import React from "react";
import StaffManagement from "../../../component/Operations/StaffManagement";

const StaffManagementScreen = () => {
  return (
    <div>
      <StaffManagement />
    </div>
  );
};

export default StaffManagementScreen;
