import React from "react";
import NetAvailabilityReport from "../../../component/CounterReports/NetAvailabilityReport";

const NetAvailabilityReportScreen = () => {
  return (
    <div>
      <NetAvailabilityReport />
    </div>
  );
};

export default NetAvailabilityReportScreen;
