export const formFieldValueMap = {
  username: "Test",
};

export const Role = [
  { id: 1, name: "Guest" },
  { id: 2, name: "Superadmin" },
  { id: 1, name: "Admin" },
  { id: 2, name: "Employee" },
];

export const Status = [
  { id: 1, name: "Active" },
  { id: 2, name: "Inactive" },
];

export const LocationOptions = [
  { label: "Toronto Pearson Intl. Airport (YYZ)", value: "YYZ" },
  { label: "Calgary Intl. Airport (YYC)", value: "YYC" },
  { label: "Mississauga", value: "MISS" },
  { label: "St. Maarten Intl. Airport (SXM)", value: "SXM" },
  { label: "Calgary", value: "CAL" },
  { label: "Denver Intl. Airport (DEN)", value: "DEN" },
  { label: "Miami Intl. Airport (MIA)", value: "MIA" },
  { label: "Fort Lauderdale Intl. Airport (FLL)", value: "FLL" },
  { label: "Orlando Intl. Airport (MCO)", value: "MCO" },
  { label: "Tampa Intl. Airport (TPA)", value: "TPA" },
  { label: "Houston Intl. Airport (IAH)", value: "IAH" },
];

export const initialData = [
  {
    id: 1,
    name: "Location Manager",
    view: false,
    create: false,
    edit: false,
    children: [
      {
        id: 2,
        name: "Locations Settings",
        view: false,
        create: false,
        edit: false,
      },
      {
        id: 3,
        name: "Holiday",
        view: false,
        create: false,
        edit: false,
      },
      {
        id: 4,
        name: "Vehicles",
        view: false,
        create: false,
        edit: false,
        subChildren: [
          {
            id: 5,
            name: "Car Classes",
            view: false,
            create: false,
            edit: false,
          },
        ],
      },
      {
        id: 6,
        name: "Settings",
        view: false,
        create: false,
        edit: false,
        subChildren: [
          {
            id: 7,
            name: "Days of Week",
            view: false,
            create: false,
            edit: false,
          },
          {
            id: 8,
            name: "Pay Now Discount",
            view: false,
            create: false,
            edit: false,
            subChildren: [
              {
                id: 9,
                name: "Pay Now Option",
                view: false,
                create: false,
                edit: false,
              },
            ],
          },
        ],
      },
      {
        id: 10,
        name: "Extra Product",
        view: false,
        create: false,
        edit: false,
        subChildren: [
          {
            id: 11,
            name: "Extra Product Category",
            view: false,
            create: false,
            edit: false,
          },
          {
            id: 12,
            name: "Extra Product Charge",
            view: false,
            create: false,
            edit: false,
          },
        ],
      },
    ],
  },
  {
    id: 13,
    name: "Rate Manager",
    view: false,
    create: false,
    edit: false,
    children: [
      {
        id: 14,
        name: "Rate Code",
        view: false,
        create: false,
        edit: false,
      },
      {
        id: 15,
        name: "Rate Plans",
        view: false,
        create: false,
        edit: false,
      },
      {
        id: 16,
        name: "Rate Types",
        view: false,
        create: false,
        edit: false,
      },
    ]
  }
];
