import React from "react";
import Forms from "../../../component/Operations/Forms";

const FormsScreen = () => {
  return (
    <div>
      <Forms />
    </div>
  );
};

export default FormsScreen;
