import {
  arrayBufferDownload,
  arrayBufferDownloadExcel,
  downloadCSV,
} from "../../../../utils/download";
import {
  decorateEmptyGridValues,
  setColumnDefaultExtraProps,
} from "../../../../utils/table/gridUtils";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_RESERVATIONS_DAILYMANIFESTREPORT_LIST,
  TOAST,
  FETCH_RESERVATIONS_DAILYMANIFESTREPORT_INIT,
  FETCH_RESERVATIONS_DAILYMANIFESTREPORT_DETAIL,
  EXPORT_RESERVATIONS_DAILYMANIFESTREPORT,
  CREATE_RESERVATIONS_DAILYMANIFESTREPORT,
  EDIT_RESERVATIONS_DAILYMANIFESTREPORT,
  INIT_CREATE_RESERVATIONS_DAILYMANIFESTREPORT_SCREEN,
  RESERVATIONS_DAILYMANIFESTREPORTDD,
  RESERVATIONS_DAILYMANIFESTREPORTDELETE,
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  SET_IS_SEARCH,
} from "../../../types/Reservations/DailyManifestReport.type";

const formFieldValueMap = {
  id: null,
  description: "",
  modBy: "",
  modTime: "",
  status: 1,
  title: "",
  userId: "",
};

const INITIAL_STATE = {
  ReservationsDailyManifestReportListview: [],
  ReservationsDailyManifestReportDD: [],
  isReservationsDailyManifestReportDDSuccess: false,
  isReservationsDailyManifestReportDDError: false,
  isLoading: false,
  error: null,
  formFieldValueMap,
  isSearch: false,
  isReservationsDailyManifestReportCreateSuccess: false,
  isReservationsDailyManifestReportCreateError: false,
  isReservationsDailyManifestReportDetailSuccess: false,
  isReservationsDailyManifestReportDetailError: false,
  isReservationsDailyManifestReportEditSuccess: false,
  isReservationsDailyManifestReportEditError: false,
  isReservationsDailyManifestReportExportSuccess: false,
  isReservationsDailyManifestReportExportError: false,
  isUploadImageSuccess: false,
  isUploadImageError: false,
  imageUploadResponse: null,
  isReservationsDailyManifestReportDeleteSuccess: false,
  isReservationsDailyManifestReportDeleteError: false,
};

const tranformToGridView = (data) => {
  const rows = data.map((item) => {
    const {
      id,
      ref,
      networkBookingNo,
      networkTitle,
      locationTitle,
      name,
      vehicle,
      bookingDate,
      pickup,
      dropoff,
      currencyCode,
      totalAmount,
      paymentStatus,
      status,
      days,
      rateType,
    } = item;

    const transformedValues = {
      id,
      ref,
      networkBookingNo,
      networkTitle,
      locationTitle,
      name,
      vehicle,
      bookingDate,
      pickup,
      dropoff,
      currencyCode,
      totalAmount,
      paymentStatus,
      status,
      days,
      rateType,
    };
    const decoratedValues = {};
    Object.keys(transformedValues).forEach((key) => {
      decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
    });
    return decoratedValues;
  });

  const columns = [
    {
      colLabel: "ID",
      dataKey: "id",
      extraProps: {
        isSortable: true,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },

    {
      colLabel: "Booking Date",
      dataKey: "bookingDate",
      extraProps: {
        isSortable: true,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },
    {
      colLabel: "Pickup Date",
      dataKey: "pickup",
      extraProps: {
        isSortable: true,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },

    {
      colLabel: "Res#",
      dataKey: "ref",
      extraProps: {
        isSortable: true,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },
    {
      colLabel: "Network Booking",
      dataKey: "networkTitle",
      extraProps: {
        isSortable: true,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },
    {
      colLabel: "Name",
      dataKey: "name",
      extraProps: {
        isSortable: true,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },
    {
      colLabel: "Est. Total",
      dataKey: "totalAmount",
      extraProps: {
        isSortable: true,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },
    {
      colLabel: "Currency",
      dataKey: "currencyCode",
      extraProps: {
        isSortable: true,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },
    {
      colLabel: "Booking Date",
      dataKey: "bookingDate",
      extraProps: {
        isSortable: true,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },
    {
      colLabel: "Network",
      dataKey: "networkBookingNo",
      extraProps: {
        isSortable: true,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },

    {
      colLabel: "Locaiton",
      dataKey: "locationTitle",
      extraProps: {
        isSortable: true,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },
    {
      colLabel: "Vehilce",
      dataKey: "vehicle",
      extraProps: {
        isSortable: true,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },
    {
      colLabel: "Payment Status",
      dataKey: "paymentStatus",
      extraProps: {
        isSortable: true,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },
    {
      colLabel: "Status",
      dataKey: "status",
      extraProps: {
        isSortable: true,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },
  ];

  return {
    datas: { columns, rows },
  };
};

export const ReservationsDailyManifestReportReducer = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case FETCH_RESERVATIONS_DAILYMANIFESTREPORT_INIT:
      return { ...INITIAL_STATE, isLoadingPage: true };
    case FETCH_RESERVATIONS_DAILYMANIFESTREPORT_DETAIL.START:
    case EXPORT_RESERVATIONS_DAILYMANIFESTREPORT.START:
    case UPLOAD_IMAGE.START:
    case FETCH_RESERVATIONS_DAILYMANIFESTREPORT_LIST.START:
    case CREATE_RESERVATIONS_DAILYMANIFESTREPORT.START:
    case RESERVATIONS_DAILYMANIFESTREPORTDELETE.START:
    case EDIT_RESERVATIONS_DAILYMANIFESTREPORT.START:
    case RESERVATIONS_DAILYMANIFESTREPORTDD.START:
      return { ...state, isLoading: true };

    case FETCH_RESERVATIONS_DAILYMANIFESTREPORT_LIST.SUCCESS: {
      const { page, data } = action.payload?.data;

      let resetState = {
        ReservationsDailyManifestReportListview: [],
        paginationInfo: null,
        error: null,
        isLoading: false,
        isLoadingPage: false,
        isPageLevelError: false,
        isSearch: false,
        isReservationsDailyManifestReportCreateSuccess: false,
        isReservationsDailyManifestReportCreateError: false,
      };
      if (!data) {
        return resetState;
      }
      const { number, size, totalElements, totalPages } = page;
      const { datas } = tranformToGridView(data);
      return {
        ...resetState,
        ReservationsDailyManifestReportListview: datas,
        paginationInfo: {
          pageSize: size,
          totalRecords: totalElements,
          totalPages: totalPages,
          pageNumber: number,
        },
      };
    }
    case FETCH_RESERVATIONS_DAILYMANIFESTREPORT_LIST.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        ReservationsDailyManifestReportListview: [],
        isLoading: false,
        isLoadingPage: false,
        error,
        isPageLevelError: true,
        isReservationsDailyManifestReportDeletionSuccess: false,
        isReservationsDailyManifestReportDeletionError: false,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }

    case FETCH_RESERVATIONS_DAILYMANIFESTREPORT_DETAIL.SUCCESS: {
      const temp = action.payload;
      if (action.payload && typeof action.payload === "object") {
        Object.keys(action.payload).forEach((key) => {
          if (formFieldValueMap.hasOwnProperty(key)) {
            temp[key] = action.payload[key];
          }
        });
      }

      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isReservationsDailyManifestReportDetailSuccess: true,
      };
    }
    case FETCH_RESERVATIONS_DAILYMANIFESTREPORT_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isReservationsDailyManifestReportDetailSuccess: false,
        isReservationsDailyManifestReportDetailError: true,
      };
    }

    //DD
    case RESERVATIONS_DAILYMANIFESTREPORTDD.SUCCESS: {
      const temp = action.payload;

      return {
        ...state,
        isLoading: false,
        ReservationsDailyManifestReportDD: temp,
        isReservationsDailyManifestReportDDSuccess: true,
        isReservationsDailyManifestReportDDError: false,
      };
    }
    case RESERVATIONS_DAILYMANIFESTREPORTDD.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isReservationsDailyManifestReportDDSuccess: false,
        isReservationsDailyManifestReportDDError: true,
      };
    }
    //END DD
    //DELETE

    case SET_DELETE_CONFIRMATION_POPUP_VISIBILITY: {
      const shouldOpenDeleteConfirmationPopup = action.payload;
      return {
        ...state,
        shouldOpenDeleteConfirmationPopup,
        isReservationsDailyManifestReportDeleteSuccess: false,
        isReservationsDailyManifestReportDeleteError: false,
      };
    }
    case RESERVATIONS_DAILYMANIFESTREPORTDELETE.SUCCESS: {
      const temp = action.payload;

      return {
        ...state,
        isLoading: false,
        isReservationsDailyManifestReportDeleteSuccess: true,
        isReservationsDailyManifestReportDeleteError: false,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }
    case RESERVATIONS_DAILYMANIFESTREPORTDELETE.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isReservationsDailyManifestReportDeleteSuccess: false,
        isReservationsDailyManifestReportDeleteError: true,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }
    //END DD

    // UPLOAD_IMAGE Start
    case UPLOAD_IMAGE.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        isLoading: false,
        isLoadingPage: false,
        error,
        isUploadImageSuccess: false,
        isUploadImageError: true,
        shouldOpenExportConfirmationPopup: false,
      };
    }

    case UPLOAD_IMAGE.SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        imageUploadResponse: data,
        isUploadImageSuccess: true,
        isUploadImageError: false,
        shouldOpenExportConfirmationPopup: false,
      };
    }

    // UPLOAD_IMAGE End

    // Export Start
    case EXPORT_RESERVATIONS_DAILYMANIFESTREPORT.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        isLoading: false,
        isLoadingPage: false,
        error,
        isReservationsDailyManifestReportExportSuccess: false,
        isReservationsDailyManifestReportExportError: true,
        shouldOpenExportConfirmationPopup: false,
      };
    }

    case EXPORT_RESERVATIONS_DAILYMANIFESTREPORT.SUCCESS: {
      const data = action.payload;
      arrayBufferDownloadExcel(data, "daily-manifest-report.xls");
      return {
        ...state,
        isLoading: false,
        isReservationsDailyManifestReportExportSuccess: true,
        isReservationsDailyManifestReportExportError: false,
        shouldOpenExportConfirmationPopup: false,
      };
    }

    // Export End

    // EDIT
    case EDIT_RESERVATIONS_DAILYMANIFESTREPORT.ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isLoading: false,
        isReservationsDailyManifestReportEditSuccess: false,
        isReservationsDailyManifestReportEditError: true,
      };
    }

    case EDIT_RESERVATIONS_DAILYMANIFESTREPORT.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isReservationsDailyManifestReportEditSuccess: true,
        isReservationsDailyManifestReportEditError: false,
      };
    }

    // EDIT END

    // Create
    case INIT_CREATE_RESERVATIONS_DAILYMANIFESTREPORT_SCREEN: {
      return { ...INITIAL_STATE };
    }

    case CREATE_RESERVATIONS_DAILYMANIFESTREPORT.ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isLoading: false,
        isReservationsDailyManifestReportCreateSuccess: false,
        isReservationsDailyManifestReportCreateError: true,
      };
    }
    case CREATE_RESERVATIONS_DAILYMANIFESTREPORT.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isReservationsDailyManifestReportCreateSuccess: true,
        isReservationsDailyManifestReportCreateError: false,
      };
    }

    case SET_IS_SEARCH: {
      return {
        ...state,
        isSearch: action.payload,
      };
    }  
    //End

    case TOAST.RESET: {
      return {
        ...state,
        isReservationsDailyManifestReportCreateSuccess: false,
        isReservationsDailyManifestReportCreateError: false,
        isReservationsDailyManifestReportEditError: false,
        isReservationsDailyManifestReportEditSuccess: false,
        isReservationsDailyManifestReportDetailError: false,
      };
    }
    default:
      return state;
  }
};
