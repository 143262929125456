import React, { useEffect, useState } from "react";
import Users from "../../../component/System/Users";
import { generatePath, useNavigate } from "react-router-dom";
import { APP_URLS } from "../../../utils/constant";
import usersData from "./tableData.json";
import { connect } from "react-redux";
import { loadingShow } from '../../../services/PostAPI';
import { fetchUser, initializeUserInfoScreen } from "../../../redux/actions/System/Users";

const UserScreen = (props) => {
  const {
    fetchUser,
    initializeUserInfoScreen,
    showPageLevelLoader,
    userListView,
    paginationInfo,
    isLoading,
  } = props;

  useEffect(() => {
    initializeUserInfoScreen();
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const navigate = useNavigate();
  const [confirmationPopup, setConfirmationPopup] = useState(false);

  const handleCreateUsers = () => {
    navigate(APP_URLS.SYSTEM_USERS.CREATE);
  };

  const handleEditUsers = (id) => {
    const path = generatePath(APP_URLS.SYSTEM_USERS.EDIT, {
      id,
    });
    navigate(path);
  };

  const setDeleteConfirmationPopupVisibility = (payload) => {
    setConfirmationPopup(payload);
  };

  const loadLazyData = (props) => {
    let newProps={
      ...props,
      userId:localStorage.getItem("userId")
    }
    fetchUser(newProps);
  };

  const UsersProps = {
    handleCreateUsers,
    handleEditUsers,
    ...usersData,
    tableData: {
      ...usersData.tableData,
      rows: userListView,
    },
    paginationInfo: {
      ...usersData.paginationInfo,
      pageSize: paginationInfo?.pageSize,
      pageNumber: paginationInfo?.pageNumber,
      totalPages: paginationInfo?.totalPages,
      totalRecords: paginationInfo?.totalRecords,
    },
    fetchUsersInfos: loadLazyData,
    isLoading,
    setDeleteConfirmationPopupVisibility,
  };

  if (showPageLevelLoader) {
    loadingShow("block");
    return null;
  }
  loadingShow("none");

  return (
    <div>
      <Users {...UsersProps} />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    initializeUserInfoScreen: () => dispatch(initializeUserInfoScreen()),
    fetchUser: (payload) => dispatch(fetchUser(payload)),
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    userListView: selectUsersList(state.userReducer),
    showPageLevelLoader: selectIsLoadingPage(state.userReducer),
    paginationInfo: selectPaginationInfo(state.userReducer),
    isPageLevelError: selectIsPageLevelError(state.userReducer),
    isFetchingGridData: selectIsLoading(state.userReducer),
  };
};

const selectUsersList = (userReducer) => userReducer?.userListView;
const selectIsLoadingPage = (userReducer) => userReducer?.isLoadingPage;
const selectPaginationInfo = (userReducer) => userReducer?.paginationInfo;
const selectIsPageLevelError = (userReducer) => userReducer?.isPageLevelError;
const selectIsLoading = (userReducer) => userReducer?.isLoading;

export default connect(mapStateToProps, mapDispatchToProps)(UserScreen);

