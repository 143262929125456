import React from "react";
import Claims from "../../../component/ClaimsManager/Claims";

const ClaimsScreen = () => {
  return (
    <div>
      <Claims />
    </div>
  );
};

export default ClaimsScreen;
