import React from "react";
import Blogs from "../../../component/Website/Blogs";

const BlogsScreen = () => {
  return (
    <div>
      <Blogs />
    </div>
  );
};

export default BlogsScreen;
