import React from "react";
import PayOnArrivalBookings from "../../../component/Reports/PayOnArrivalBookings";

const PayOnArrivalBookingsScreen = () => {
  return (
    <div>
      <PayOnArrivalBookings />
    </div>
  );
};

export default PayOnArrivalBookingsScreen;
