import { UsersService } from "../../../../services";
import { FETCH_USERS_LIST, FETCH_USERINFO_INIT } from "../../../types/Systems/Users.type";

export const initializeUserInfoScreen = () => ({ type: FETCH_USERINFO_INIT });

export const userFetchStart = (payload) => ({
  type: FETCH_USERS_LIST.START,
  payload,
});

export const userFetchSuccess = (payload) => ({
  type: FETCH_USERS_LIST.SUCCESS,
  payload,
});

export const userFetchError = (payload) => ({
  type: FETCH_USERS_LIST.ERROR,
  payload,
});

export const fetchUser = (payload) => {
  return (dispatch) => {
    dispatch(userFetchStart(payload));
    UsersService.fetchUser(payload).then((userData) => {
      if (!userData.isError) {
        dispatch(userFetchSuccess(userData));
      } else {
        dispatch(userFetchError(userData));
      }
    });
  };
};
