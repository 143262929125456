import { FETCH_USERS_LIST, FETCH_USERINFO_INIT } from "../../../types/Systems/Users.type";

const INITIAL_STATE = {
  userListView: [],
  isLoading: false,
  error: null,
};

const transformUserData = (content) => {
  return content.map((user) => ({
    id: user.id,
    username: user.username,
    role: user.role?.title || "",
    location: user.locations && user.locations.length > 0
      ? `${user.locations[0].title}, ${user.locations[0].address}, ${user.locations[0].city}, ${user.locations[0].province}, ${user.locations[0].country}`
      : "N/A",
    alias: user.alias,
    cart_by: user.role?.createdBy || "N/A",
    cart_Time: user?.creationTimestamp || "N/A",
    edit_time: user.modificationTimestamp || "N/A",
    status: user.status,
  }));
};

export const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_USERINFO_INIT:
      return { ...INITIAL_STATE, isLoadingPage: true };
    case FETCH_USERS_LIST.START:
      return { ...state, isLoading: true };

    case FETCH_USERS_LIST.SUCCESS: {
      const { page, data } = action.payload;

      let resetState = {
        userListView: [],
        paginationInfo: null,
        error: null,
        isLoading: false,
        isLoadingPage: false,
        isPageLevelError: false,
        isCreateUserSuccess: false,
        isCreateUserError: false,
      };
      if (!data || data.length === 0) {
        return resetState;
      }
      const { number, size, totalElements, totalPages } = page;
      return {
        ...resetState,
        userListView: transformUserData(data),
        paginationInfo: {
          pageSize: size,
          totalRecords: totalElements,
          totalPages:totalPages,
          pageNumber: number,
        },
      };
    }

    case FETCH_USERS_LIST.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        userListView: [],
        isLoading: false,
        isLoadingPage: false,
        error,
        isPageLevelError: true,
      };
    }
    default:
      return state;
  }
};