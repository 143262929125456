import React from "react";
import NoShowManifest from "../../../component/Reports/NoShowManifest";

const NoShowManifestScreen = () => {
  return (
    <div>
      <NoShowManifest />
    </div>
  );
};

export default NoShowManifestScreen;
