import React from "react";
import Newsletters from "../../../component/Operations/Newsletters";

const NewslettersScreen = () => {
  return (
    <div>
      <Newsletters />
    </div>
  );
};

export default NewslettersScreen;
