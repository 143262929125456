import React from "react";
import PaymentsReport from "../../../component/CounterReports/PaymentsReport";

const PaymentsReportScreen = () => {
  return (
    <div>
      <PaymentsReport />
    </div>
  );
};

export default PaymentsReportScreen;
