import React from "react";
import WholesaleBillOfSale from "../../../component/Financial/WholesaleBillOfSale";

const WholesaleBillOfSaleScreen = () => {
  return (
    <div>
      <WholesaleBillOfSale />
    </div>
  );
};

export default WholesaleBillOfSaleScreen;
