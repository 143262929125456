import React from "react";
import Invoicing from "../../../component/Financial/Invoicing";

const InvoicingScreen = () => {
  return (
    <div>
      <Invoicing />
    </div>
  );
};

export default InvoicingScreen;
