import React from "react";
import DailyBookingsStatistics from "../../../component/Reports/DailyBookingsStatistics";

const DailyBookingsStatisticsScreen = () => {
  return (
    <div>
      <DailyBookingsStatistics />
    </div>
  );
};

export default DailyBookingsStatisticsScreen;
