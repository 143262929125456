import React, { useState, useCallback, useMemo } from "react";
import { PanelMenu } from "primereact/panelmenu";
import { useNavigate } from "react-router-dom";
import { items as initialItems } from "./menu";
import "./style.css";

const rootKeys = initialItems.map((item) => item.key);

const SideNav = () => {
  const navigate = useNavigate();
  const [expandedKeys, setExpandedKeys] = useState({});
  const [favorites, setFavorites] = useState(() => {
    return JSON.parse(localStorage.getItem("favorites")) || [];
  });

  const handleExpandedKeysChange = useCallback((newExpandedKeys) => {
    let updatedKeys = {};

    const newlyOpenedKeys = Object.keys(newExpandedKeys).filter(
      (key) => newExpandedKeys[key]
    );
    if (newlyOpenedKeys.length > 0) {
      const lastOpenedKey = newlyOpenedKeys[newlyOpenedKeys.length - 1];

      if (rootKeys.includes(lastOpenedKey)) {
        updatedKeys = { [lastOpenedKey]: true };
      } else {
        updatedKeys = newExpandedKeys;
      }
    }
    setExpandedKeys(updatedKeys);
  }, []);

  const handleNavigate = useCallback(
    (path) => {
      navigate(`/${path}`);
    },
    [navigate]
  );

  const toggleFavorite = useCallback((key, label, path) => {
    setFavorites((prevFavorites) => {
      const isFav = prevFavorites.some((fav) => fav.key === key);
      const updatedFavorites = isFav
        ? prevFavorites.filter((fav) => fav.key !== key)
        : [...prevFavorites, { key, label, path }];

      localStorage.setItem("favorites", JSON.stringify(updatedFavorites));
      return updatedFavorites;
    });
  }, []);

  const renderMenuItems = useCallback(
    (menuItems) => {
      return menuItems.map((item) => {
        if (item.key === "favourites") {
          return {
            ...item,
            items: favorites.map((fav) => ({
              label: fav.label,
              key: fav.key,
              path: fav.path,
              icon: "pi pi-star-fill",
              command: () => handleNavigate(fav.path),
            })),
          };
        }

        const isFavorite = favorites.some((fav) => fav.key === item.key);
        const isDashboard = item.key === "dashboard";

        return {
          ...item,
          ...(item.items
            ? { items: renderMenuItems(item.items) }
            : {
                template: (menuItem, options) => (
                  <div className={options.className} onClick={options.onClick}>
                    {item.icon && <i className={`${item.icon}`} />}
                    {!isDashboard && (
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleFavorite(item.key, item.label, item.path);
                        }}
                        style={{ marginRight: "10px", cursor: "pointer" }}
                      >
                        <i
                          className={`pi ${
                            isFavorite ? "pi-star-fill" : "pi-star"
                          }`}
                        />
                      </span>
                    )}
                    <span>{menuItem.label}</span>
                  </div>
                ),
                command: () => handleNavigate(item.path),
              }),
        };
      });
    },
    [favorites, handleNavigate, toggleFavorite]
  );

  const menuModel = useMemo(
    () => renderMenuItems(initialItems),
    [renderMenuItems]
  );

  return (
    <div className="sidenav-container">
      <PanelMenu
        model={menuModel}
        className="custom-panelmenu"
        expandedKeys={expandedKeys}
        onExpandedKeysChange={handleExpandedKeysChange}
      />
    </div>
  );
};

export default SideNav;
