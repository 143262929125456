import React from "react";
import FleetPerformanceReport from "../../../component/CounterReports/FleetPerformanceReport";

const FleetPerformanceReportScreen = () => {
  return (
    <div>
      <FleetPerformanceReport />
    </div>
  );
};

export default FleetPerformanceReportScreen;
