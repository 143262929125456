import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
  Grid,
  Container,
  FormControlLabel,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import { useStyles } from "../style";
import { Button } from "primereact/button";
import { APP_URLS } from "../../../../utils/constant";
import EzControlledInputWithFloatingLabel from "../../../../common/EzForm/EzControlledInput/WithFloatingLabel";
import { useForm } from "react-hook-form";
import "./index.css";
import {
  formFieldValueMap,
  initialData,
  LocationOptions,
  Role,
  Status,
} from "./constant";
import EzControlledDropDowntWithFloatingLabel from "../../../../common/EzForm/EzControlledDropDown/WithFloatingLabel";
import EzControlledMultiSelecttWithFloatingLabel from "../../../../common/EzForm/EzControlledMultiSelect/WithFloatingLabel";
import UserAccessRow from "./UserAccessRow";
import { BorderAll } from "@mui/icons-material";

const CreateUsersComponent = () => {
  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: useMemo(() => {
      console.log("check value come or not", formFieldValueMap);
      return formFieldValueMap;
    }, [formFieldValueMap]),
  });
  const classes = useStyles();
  const [data, setData] = useState(initialData);
  const [selectAll, setSelectAll] = useState(false);

  const handleChange = (id, permissionType) => {
    const updatePermissions = (items) =>
      items.map((item) => ({
        ...item,
        [permissionType]:
          item.id === id ? !item[permissionType] : item[permissionType],
        children: item.children ? updatePermissions(item.children) : undefined,
        subChildren: item.subChildren
          ? updatePermissions(item.subChildren)
          : undefined,
      }));

    setData(updatePermissions(data));
  };

  const handleSelectAll = () => {
    const newState = !selectAll;
    const updateAll = (items) =>
      items.map((item) => ({
        ...item,
        view: newState,
        create: newState,
        edit: newState,
        children: item.children ? updateAll(item.children) : undefined,
        subChildren: item.subChildren ? updateAll(item.subChildren) : undefined,
      }));

    setData(updateAll(data));
    setSelectAll(newState);
  };

  const navigateToUsersScreen = () => {
    window.location.replace("/#" + APP_URLS.SYSTEM_USERS.HOME);
  };

  const onSubmit = (data) => {};

  const getToastProps = () => {
    const toastTitle = "Please select category from list";
    return {
      severity: TOAST_SEVERITY.ERROR,
      toastTitle,
      shouldShowToast: false,
    };
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const renderPageHeaderSection = () => {
    return (
      <div className="make-relative sub-header ">
        <div className="maintitlediv">
          <div className="ml-3">
            <h4 className="pagetitle">
              Update User: {formFieldValueMap?.username}
            </h4>
          </div>
          <div className="buttonarea">
            <Button
              tooltip={"Back to Users"}
              tooltipOptions={{ position: "left" }}
              label={"Back to Users"}
              // icon={"pi pi-arrow-left"}
              className={`p-button `}
              style={{ borderRadius: "10px" }}
              onClick={navigateToUsersScreen}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Container maxWidth="lg">
      <EzToast {...getToastProps()} />
      <div className={`containerbox ${classes.bg}`}>
        {renderPageHeaderSection()}
        <div className="form-demo" style={{ marginTop: "20px" }}>
          <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <EzControlledInputWithFloatingLabel
                  name="Username"
                  control={control}
                  label="Username"
                  rules=""
                  isError={errors["Username"]}
                  errorMsg={getFormErrorMessage("Username")}
                />
              </Grid>
              <Grid item xs={6}>
                <EzControlledInputWithFloatingLabel
                  name="Password"
                  control={control}
                  label="Password"
                  rules=""
                  isError={errors["Username"]}
                  errorMsg={getFormErrorMessage("Username")}
                />
              </Grid>
              <Grid item xs={6}>
                <EzControlledDropDowntWithFloatingLabel
                  name="role"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  optionLabel={"name"}
                  optionValue={"id"}
                  options={Role}
                  isError={errors["item?.name"]}
                  errorMsg={getFormErrorMessage("item?.name")}
                  label="Role"
                  labelClassName={"ml-2"}
                />
              </Grid>
              <Grid item xs={6}>
                <EzControlledDropDowntWithFloatingLabel
                  name="status"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  optionLabel={"name"}
                  optionValue={"id"}
                  options={Status}
                  isError={errors["item?.name"]}
                  errorMsg={getFormErrorMessage("item?.name")}
                  label="Status"
                  labelClassName={"ml-2"}
                />
              </Grid>
              <Grid item xs={6}>
                <EzControlledMultiSelecttWithFloatingLabel
                  name="location"
                  control={control}
                  rules={"item?.rule"}
                  optionLabel={"label"}
                  optionValue={"value"}
                  options={LocationOptions}
                  isError={errors["item?.name"]}
                  errorMsg={getFormErrorMessage("item?.name")}
                  label="Location"
                  labelClassName={"ml-2"}
                />
              </Grid>
              <Grid item xs={6}>
                <EzControlledInputWithFloatingLabel
                  name="alias"
                  control={control}
                  label="Alias"
                  rules={{
                    required: true,
                  }}
                  isError={errors["Alias"]}
                  errorMsg={getFormErrorMessage("Alias")}
                />
              </Grid>
              <Grid item xs={3}>
                <EzControlledInputWithFloatingLabel
                  name="editTime"
                  control={control}
                  label="Edit Time"
                  rules={{
                    required: true,
                  }}
                  isError={errors["editTime"]}
                  errorMsg={getFormErrorMessage("editTime")}
                />
              </Grid>
              <Grid item xs={3}>
                <EzControlledInputWithFloatingLabel
                  name="editBy"
                  control={control}
                  label="Edit By"
                  rules={{
                    required: true,
                  }}
                  isError={errors["editBy"]}
                  errorMsg={getFormErrorMessage("editBy")}
                />
              </Grid>
            </Grid>
            <Grid>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "60%",
                  marginTop: "20px",
                  padding: "10px",
                }}
              >
                <h1 style={{ margin: 0 }}>User Access</h1>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectAll}
                      onChange={handleSelectAll}
                      style={{ color: "blue" }}
                    />
                  }
                  label="Select All"
                />
              </div>
              <TableContainer
                component={Paper}
                sx={{ boxShadow: "none", border: "1px solid #ccc" }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ py: 1, pl: "3px" }}>
                        Module Name
                      </TableCell>
                      <TableCell sx={{ padding: 0 }}>View</TableCell>
                      <TableCell sx={{ padding: 0 }}>Create</TableCell>
                      <TableCell sx={{ padding: 0 }}>Edit</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((module) => (
                      <UserAccessRow
                        key={module.id}
                        module={module}
                        handleChange={handleChange}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Button
              type="submit"
              label={"Save"}
              className="mt-2 p-button-raised w-10"
            />
          </form>
        </div>
      </div>
    </Container>
  );
};

export default CreateUsersComponent;
