import React from "react";
import { TableRow, TableCell, Checkbox } from "@mui/material";
import UserAccessSubRow from "../UserAccessSubRow";

const UserAccessRow = ({ module, handleChange }) => {
  console.log("module", module);

  return (
    <>
      <TableRow sx={{ fontWeight: "bold", backgroundColor: "#d7f2ed" }}>
        <TableCell sx={{ py: 0, pl: "3px" }}>{module.name}</TableCell>
        <TableCell sx={{ padding: 0 }}>
          <Checkbox
            checked={module.view}
            onChange={() => handleChange(module.id, "view")}
            style={{ color: "blue" }}
          />
        </TableCell>
        <TableCell sx={{ padding: 0 }}>
          <Checkbox
            checked={module.create}
            onChange={() => handleChange(module.id, "create")}
            style={{ color: "blue" }}
          />
        </TableCell>
        <TableCell sx={{ padding: 0 }}>
          <Checkbox
            checked={module.edit}
            onChange={() => handleChange(module.id, "edit")}
            style={{ color: "blue" }}
          />
        </TableCell>
      </TableRow>
      {module.children?.map((child) => (
        <React.Fragment key={child.id}>
          <TableRow
            sx={child.subChildren ? { backgroundColor: "#fff2d6" } : {}}
          >
            <TableCell sx={{ pl: 3, py: 0 }}>{child.name}</TableCell>
            <TableCell sx={{ padding: 0 }}>
              <Checkbox
                checked={child.view}
                onChange={() => handleChange(child.id, "view")}
                style={{ color: "blue" }}
              />
            </TableCell>
            <TableCell sx={{ padding: 0 }}>
              <Checkbox
                checked={child.create}
                onChange={() => handleChange(child.id, "create")}
                style={{ color: "blue" }}
              />
            </TableCell>
            <TableCell sx={{ padding: 0 }}>
              <Checkbox
                checked={child.edit}
                onChange={() => handleChange(child.id, "edit")}
                style={{ color: "blue" }}
              />
            </TableCell>
          </TableRow>

          {child.subChildren?.map((sub) => (
            <UserAccessSubRow
              key={sub.id}
              sub={sub}
              handleChange={handleChange}
            />
          ))}
        </React.Fragment>
      ))}
    </>
  );
};

export default UserAccessRow;
