export const items = [
  {
    label: "DASHBOARD",
    icon: "pi pi-th-large",
    path: "#",
    key: "dashboard",
  },
  {
    label: "FAVOURITES",
    icon: "pi pi-heart",
    key: "favourites",
    items: [],
  },
  {
    label: "LOCATION MANAGER",
    icon: "pi pi-map",
    key: "location-manager",
    items: [
      {
        label: "Location",
        path: "location",
        key: "location",
      },
      {
        label: "Settings",
        path: "#",
        key: "settings",
        items: [
          { label: "Days of Week", path: "days-of-week", key: "days-of-week" },
          {
            label: "Pay Now Discount",
            path: "pay-now-discount",
            key: "pay-now-discount",
          },
          {
            label: "Pay Now Option",
            path: "pay-now-option",
            key: "pay-now-option",
          },
        ],
      },
      {
        label: "Holidays",
        path: "holidays",
        key: "holidays",
      },
      { label: "Reservation", path: "reservations", key: "reservations" },
      {
        label: "Vehicles",
        path: "#",
        key: "vehicles",
        items: [
          {
            label: "Vehicle Category",
            path: "vehicle-category",
            key: "vehicle-category",
          },
          { label: "Make", path: "vehicle-make", key: "vehicle-make" },
          { label: "Model", path: "vehicle-model", key: "vehicle-model" },
          { label: "Trim", path: "vehicle-trim", key: "vehicle-trim" },
          {
            label: "Vehicle Transmission Type",
            path: "vehicle-transmission-type",
            key: "vehicle-transmission-type",
          },
          {
            label: "Vehicle AcType",
            path: "vehicle-ac-type",
            key: "vehicle-ac-type",
          },
          {
            label: "Vehicle StereoType",
            path: "vehicle-stereo-type",
            key: "vehicle-stereo-type",
          },
          {
            label: "Vehicle VehicleOperations",
            path: "vehicle-operations",
            key: "vehicle-operations",
          },
        ],
      },
      {
        label: "Incident",
        path: "incident",
        key: "incident",
        items: [
          {
            label: "Fault Values",
            path: "fault-values",
            key: "fault-values",
          },
          {
            label: "Incident Report",
            path: "incident-report",
            key: "incident-report",
          },
        ],
      },
    ],
  },
  {
    label: "RATE MANAGER",
    icon: "pi pi-dollar",
    key: "rate-manager",
    items: [
      { label: "Rate Code", path: "rate-code", key: "rate-code" },
      { label: "Rate Plans", path: "rate-plans", key: "rate-plans" },
      { label: "Rate Types", path: "rate-types", key: "rate-types" },
      {
        label: "Lor Adjustments",
        path: "lor-adjustments",
        key: "lor-adjustments",
      },
      { label: "Rates", path: "rates", key: "rates" },
      {
        label: "Extra Product Category",
        path: "extra-product-category",
        key: "extra-product-category",
      },
      {
        label: "Extra Product Charge",
        path: "extra-product-charge",
        key: "extra-product-charge",
      },
      { label: "Blackouts", path: "blackouts", key: "blackouts" },
      { label: "Rate Mirrors", path: "rate-mirrors", key: "rate-mirrors" },
      { label: "Networks", path: "networks", key: "networks" },
    ],
  },
  {
    label: "RESERVATION MANAGER",
    icon: "pi pi-book",
    key: "reservation-manager",
    items: [
      { label: "Rate Quotes", path: "quotes", key: "quotes" },
      {
        label: "Reservations",
        key: "reservation",
        items: [
          { label: "Reservation", path: "reservations", key: "reservations" },
          {
            label: "Reservation Detail Report",
            path: "reservation-detail-report",
            key: "reservation-detail-report",
          },
          {
            label: "Daily Manifest Report",
            path: "daily-manifest-report",
            key: "daily-pickup-manifest",
          },
          {
            label: "Bookings by Location Report",
            path: "bookings-by-location",
            key: "bookings-by-location",
          },
          {
            label: "Prepaid Bookings Report",
            path: "prepaid-bookings",
            key: "prepaid-bookings",
          },
          {
            label: "CRX/Rezp Reservations",
            path: "crx-rezp-reservations",
            key: "crx-rezp-reservations",
          },
        ],
      },
      { label: "Transactions", path: "transactions", key: "transactions" },
      { label: "Bids", path: "bids", key: "bids" },
      {
        label: "Vehicles",
        path: "vehicles",
        key: "vehicle",
      },
      {
        label: "Calendar",
        path: "#",
        key: "calendar",
        items: [
          {
            label: "Calendar Pickup",
            path: "calendar-pickup",
            key: "calendar-pickup",
          },
          {
            label: "Calendar Dropoff",
            path: "calendar-dropoff",
            key: "calendar-dropoff",
          },
        ],
      },
    ],
  },
  {
    label: "COUNTER MANAGER",
    icon: "pi pi-desktop",
    key: "counter-manager",
    items: [
      {
        label: "Revenue Agreements",
        path: "revenue-agreements",
        key: "revenue-agreements",
      },
      {
        label: "Non-Rev Movement",
        path: "non-rev-movement",
        key: "non-rev-movement",
      },
      {
        label: "Vehicles",
        path: "vehicles",
        key: "vehicle",
      },
      {
        label: "Vehicle Status",
        path: "vehicle-status",
        key: "vehicle-status",
      },
      {
        label: "Maintenance",
        path: "#",
        key: "maintenance",
        items: [
          {
            label: "Maintenance Category",
            path: "maintenance-category",
            key: "maintenance-category",
          },
          {
            label: "Maintenance Records",
            path: "maintenance-records",
            key: "maintenance-records",
          },
        ],
      },
      {
        label: "Body/Repair Shops",
        path: "body-repair-shops",
        key: "body-repair-shops",
      },
      { label: "Dealerships", path: "vehicle-dealerships", key: "dealerships" },
      {
        label: "Vehicle Inquiries",
        path: "vehicle-inquiries",
        key: "vehicle-inquiries",
      },
      {
        label: "Dealership Vehicle Deals",
        path: "vehicle-dealership-vehicle-deal",
        key: "dealership-vehicle-deals",
      },
      {
        label: "Claims Manager",
        path: "#",
        key: "claims-manager",
        items: [
          {
            label: "Claims Cost Category",
            path: "claims-cost-category",
            key: "claims-cost-category",
          },
          { label: "Claims", path: "claims", key: "claims" },
        ],
      },
      // {
      //   label: "COUNTER REPORTS",
      //   path: "#",
      //   key: "counter-reports",
      //   items: [
      //     {
      //       label: "Fleet List Report",
      //       path: "fleet-list-report",
      //       key: "fleet-list-report",
      //     },
      //     {
      //       label: "Fleet Due Back Report",
      //       path: "fleet-due-back-report",
      //       key: "fleet-due-back-report",
      //     },
      //     {
      //       label: "Circle Sheet Report",
      //       path: "circle-sheet-report",
      //       key: "circle-sheet-report",
      //     },
      //     {
      //       label: "Net Availability Report",
      //       path: "net-availability-report",
      //       key: "net-availability-report",
      //     },
      //     {
      //       label: "Fleet Performance Report",
      //       path: "fleet-performance-report",
      //       key: "fleet-performance-report",
      //     },
      //     {
      //       label: "Reservation Report",
      //       path: "reservations-report",
      //       key: "reservation-report",
      //     },
      //     {
      //       label: "Revenue Report",
      //       path: "revenue-report",
      //       key: "revenue-report",
      //     },
      //     {
      //       label: "Payments Report",
      //       path: "payments-report",
      //       key: "payments-report",
      //     },
      //     {
      //       label: "Accounts Receivable Report",
      //       path: "accounts-receivable-report",
      //       key: "accounts-receivable-report",
      //     },
      //     {
      //       label: "Rental Overdue Report",
      //       path: "rental-overdue-report",
      //       key: "rental-overdue-report",
      //     },
      //   ],
      // },
      {
        label: "Broadcast Messages",
        path: "#",
        key: "broadcast-messages",
        items: [
          {
            label: "Staff Dashboard",
            path: "broadcast-messages",
            key: "Staff Dashboard",
          },
        ],
      },
    ],
  },
  // {
  //   label: "REPORTS MANAGER",
  //   icon: "pi pi-folder-open",
  //   key: "reports-manager",
  //   items: [
  //     {
  //       label: "Daily Drop Off Manifest",
  //       path: "daily-dropoff-manifest",
  //       key: "daily-dropoff-manifest",
  //     },
  //     {
  //       label: "No Show Manifest",
  //       path: "no-show-manifest",
  //       key: "no-show-manifest",
  //     },
  //     {
  //       label: "Bookings by Location",
  //       path: "bookings-by-location",
  //       key: "bookings-by-location",
  //     },
  //     {
  //       label: "Daily Bookings Statistics",
  //       path: "daily-bookings-statistics",
  //       key: "daily-bookings-statistics",
  //     },
  //     {
  //       label: "Prepaid Bookings",
  //       path: "prepaid-bookings",
  //       key: "prepaid-bookings",
  //     },
  //     {
  //       label: "Pay on Arrival Bookings",
  //       path: "pay-on-arrival-bookings",
  //       key: "pay-on-arrival-bookings",
  //     },
  //     {
  //       label: "Branch Summary",
  //       path: "branch-summary",
  //       key: "branch-summary",
  //     },
  //   ],
  // },
  {
    label: "WEBSITE MANAGER",
    icon: "pi pi-globe",
    key: "website-manager",
    items: [
      { label: "Blogs", path: "blogs", key: "blogs" },
      {
        label: "Newsletters",
        path: "#",
        key: "newsletters-menager",
        items: [
          {
            label: "Newsletters",
            path: "newsletters",
            key: "newsletters",
          },
          {
            label: "Newsletters Subscribers",
            path: "newsletters-subscribers",
            key: "newsletters-subscribers",
          },
        ],
      },
      {
        label: "Career",
        path: "#",
        key: "career",
        items: [
          {
            label: "Career Positions",
            path: "career-positions",
            key: "career-positions",
          },
          {
            label: "Career Applications",
            path: "career-applications",
            key: "career-applications",
          },
        ],
      },
      {
        label: "Promotion",
        path: "#",
        key: "promotion",
        items: [
          {
            label: "Home Sliders",
            path: "home-sliders",
            key: "home-sliders",
          },
          {
            label: "Home Info Slider",
            path: "home-info-slider",
            key: "home-info-slider",
          },
          {
            label: "Discount Codes",
            path: "discount-codes",
            key: "discount-codes",
          },
          {
            label: "Coupon",
            path: "coupon",
            key: "coupon",
          },
        ],
      },
      {
        label: "Forms",
        path: "#",
        key: "forms",
        items: [
          {
            label: "Lost & Found Inquiries",
            path: "lost-found-inquiries",
            key: "lost-found-inquiries",
          },
          {
            label: "Contact Queries",
            path: "contact-queries",
            key: "contact-queries",
          },
        ],
      },
      
      { label: "Traffic Logs", path: "traffic-logs", key: "traffic-logs" },
      {
        label: "Renters",
        path: "#",
        key: "renters",
        items: [
          {
            label: "Registered Renters",
            path: "registered-renters",
            key: "registered-renters",
          },
          { label: "Renters Info", path: "renters-info", key: "renters-info" },
          {
            label: "Renter Interaction Log",
            path: "renter-interaction-log",
            key: "renter-interaction-log",
          },
          {
            label: "Non Revenue Agreements",
            path: "non-revenue-agreements",
            key: "non-revenue-agreements",
          },
        ],
      },
    ],
  },
  {
    label: "OPERATIONS MANAGER",
    icon: "pi pi-briefcase",
    key: "operations-manager",
    items: [
      {
        label: "Task Assignment",
        path: "task-assignment",
        key: "task-assignment",
        items: [
          {
            label: "Task Management System",
            path: "task-management-system",
            key: "ask-management-system",
          },
          { label: "Calendar", path: "calendar", key: "calendar" },
          {
            label: "Recurring to do list",
            path: "recurring-to-do-list",
            key: "recurring-to-do-list",
          },
        ],
      },
      {
        label: "Admin",
        path: "admin",
        key: "admin",
        items: [
          {
            label: "Corporate Meetings",
            path: "corporate-meetings",
            key: "corporate-meetings",
          },
          {
            label: "Leave Applications",
            path: "leave-applications",
            key: "leave-applications",
          },
        ],
      },
      // { label: "Careers", path: "careers", key: "careers" },
      // { label: "Newsletters", path: "newsletters", key: "newsletters" },
      // { label: "Forms", path: "forms", key: "forms" },
      {
        label: "Financial",
        path: "financial",
        key: "financial",
        items: [
          {
            label: "Time and Billing",
            path: "time-and-billing",
            key: "time-and-billing",
          },
          {
            label: "Vendors",
            path: "vendors",
            key: "vendors",
          },
          {
            label: "Invoicing",
            path: "invoicing",
            key: "invoicing",
          },
          {
            label: "Wholesale Bill of Sale",
            path: "wholesale-bill-of-sale",
            key: "wholesale-bill-of-sale",
          },
          {
            label: "A/R",
            path: "accounts-receivable",
            key: "accounts-receivable",
          },
          {
            label: "Misc Uploads",
            path: "misc-uploads",
            key: "misc-uploads",
          },
          {
            label: "Rental Checklist",
            path: "rental-checklist",
            key: "rental-checklist",
          },
        ],
      },
      {
        label: "Communication",
        path: "communication",
        key: "communication",
        items: [
          {
            label: "Send Notifications",
            path: "send-notifications",
            key: "send-notifications",
          },
          {
            label: "Send Bulk Email",
            path: "send-bulk-email",
            key: "send-bulk-email",
          },
          {
            label: "Send Bulk SMS",
            path: "send-bulk-sms",
            key: "send-bulk-sms",
          },
          {
            label: "Staff",
            path: "staff",
            key: "staff",
          },
          {
            label: "Calendar",
            path: "calendar",
            key: "calendar",
          },
        ],
      },
      {
        label: "Staff",
        path: "staff-management",
        key: "staff-management",
      },
      { label: "Calendar", path: "calendar", key: "calendar" },
    ],
  },
  {
    label: "SYSTEM MANAGER",
    icon: "pi pi-cog",
    key: "system-manager",
    items: [{ label: "Users", path: "users", key: "users" }],
  },
];
