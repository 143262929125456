import React from 'react';
import { Routes, Route } from "react-router-dom";
import { APP_URLS } from '../utils/constant';
import VehicleCategoryScreen from '../container/Vehicles/VehicleCategoryScreen';
import CreateVehicleCategory from '../container/Vehicles/VehicleCategoryScreen/CreateVehicleCategory';

const VehicleCategoryRouter = ({ access }) => {

  return (
    <Routes>
      <Route path={APP_URLS.VEHICLE_CATEGORY.HOME} element={<VehicleCategoryScreen />} />
      <Route path={APP_URLS.VEHICLE_CATEGORY.CREATE} element={<CreateVehicleCategory />} />
      <Route path={APP_URLS.VEHICLE_CATEGORY.EDIT} element={<CreateVehicleCategory isEdit={true} />} />
    </Routes>
  );
};

export default VehicleCategoryRouter;
