import React from "react";
import BroadcastMessages from "../../../component/CounterManager/BroadcastMessages";

const BroadcastMessagesScreen = () => {
  return (
    <div>
      <BroadcastMessages />
    </div>
  );
};

export default BroadcastMessagesScreen;
