import React from "react";
import Vendors from "../../../component/Financial/Vendors";

const VendorsScreen = () => {
  return (
    <div>
      <Vendors />
    </div>
  );
};

export default VendorsScreen;
