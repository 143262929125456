import React, { useState } from 'react';
import Router from './router';
import MainLayout from './common/Layouts';

function App() {
  const [isCookiesSet, setIsCookiesSet] = useState(localStorage.getItem('token'));

  const switchLogin = () => {
    const token = localStorage.getItem('token');
    setIsCookiesSet(token);
  };

  return isCookiesSet ? (
    <MainLayout switchHeader={switchLogin} />
  ) : (
    <Router switchHeader={switchLogin} />
  );
}

export default App;
