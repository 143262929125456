import React from "react";
import ReservationsBids from "../../../component/Reservations/ReservationsBids";

const ReservationsBidsScreen = () => {
  return (
    <div>
      <ReservationsBids />
    </div>
  );
};

export default ReservationsBidsScreen;
