import React from "react";
import ClaimsCostCategory from "../../../component/ClaimsManager/ClaimsCostCategory";

const ClaimsCostCategoryScreen = () => {
  return (
    <div>
      <ClaimsCostCategory />
    </div>
  );
};

export default ClaimsCostCategoryScreen;
