import React from "react";
import RentalChecklist from "../../../component/Financial/RentalChecklist";

const RentalChecklistScreen = () => {
  return (
    <div>
      <RentalChecklist />
    </div>
  );
};

export default RentalChecklistScreen;
