
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  layout: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    backgroundColor: theme.palette.primary.light,
  },
  header: {
    height: "60px",
    flexShrink: 0,
    zIndex: 1000,
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 4px",
  },
  main: {
    display: "flex",
    flex: 1,
    overflow: "hidden",
  },
  sideNav: {
    width: "280px",
    backgroundColor: "#ffffff",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    flexShrink: 0,
    overflowY: "auto",
  },
  contentArea: {
    flex: 1,
    overflowY: "auto",
    padding: "1rem",
  },
}));
