import { combineReducers } from "redux";
import toastReducer from "./Notification/Toast";
import { locationReducer } from "./location";
import { vehicleCategoryReducer } from "./vehicles/vehicleCategory";
import { vehicleMakeReducer } from "./vehicles/vehicleMake";
import { vehicleModelReducer } from "./vehicles/vehicleModel";
import { vehicleTrimReducer } from "./vehicles/vehicleTrim";
import { vehicleTransmissionTypeReducer } from "./vehicles/vehicleTransmissiontype";
import { vehicleAcTypeReducer } from "./vehicles/vehicleAcType";
import { vehicleStereoTypeReducer } from "./vehicles/vehicleStereoType";
import { vehicleVehicleStatusReducer } from "./vehicles/vehicleVehicleStatus";
import { changepasswordReducer } from "./changepassword";
import { vehicleVehicleOperationsReducer } from "./vehicles/vehicleVehicleOperations";
import { vehicleDealershipsReducer } from "./vehicles/vehicleDealerships";
import { vehicleBodyShopReducer } from "./vehicles/vehicleBodyShop";
import { vehicleDealershipVehicleDealReducer } from "./vehicles/vehicleDealershipVehicleDeal";
import { rateadminRateCodeReducer } from "./RateAdmin/rateadiminRateCode";
import { rateadminRatePlanReducer } from "./RateAdmin/rateadiminRatePlan";
import { rateadminRateTypesReducer } from "./RateAdmin/rateadiminRateTypes";
import { rateadminLorAdjustmentsReducer } from "./RateAdmin/rateadiminLorAdjustments";
import { rateadminRatesReducer } from "./RateAdmin/rateadiminRates";
import { rateadminExtraProductCategoryReducer } from "./RateAdmin/rateadiminExtraProductCategory";
import { rateadminExtraProductChargeReducer } from "./RateAdmin/rateadiminExtraProductCharge";
import { rateadminNetworksReducer } from "./RateAdmin/rateadiminNetworks";
import { rateadminBlackoutsReducer } from "./RateAdmin/rateadiminBlackouts";
import { rateadminRateMirrorsReducer } from "./RateAdmin/rateadiminRateMirrors";
import { holidaysReducer } from "./Holidays";
import { SettingsSettingWeekendsReducer } from "./Settings/SettingsSettingWeekends";
import { SettingsPaynowDiscountsReducer } from "./Settings/SettingsPaynowDiscounts";
import { SettingsPaynowOptionsReducer } from "./Settings/SettingsPaynowOptions";
import { RentersRentersInfoReducer } from "./Renters/RentersRentersInfo";
import { RentersRevenueAgreementsReducer } from "./Renters/RentersRevenueAgreements";
import { RentersNonRevenueAgreementsReducer } from "./Renters/RentersNonRevenueAgreements";
import { RentersVehicleInquiryReducer } from "./Renters/RentersVehicleInquiry";
import { RentersRegisteredRentersReducer } from "./Renters/RentersRegisteredRenters";
import { RentersLogInteractionsReducer } from "./Renters/RentersLogInteractions";
import { ReservationsReservationsReducer } from "./Reservations/ReservationsReservations";
import { ReservationsDetailReportReducer } from "./Reservations/ReservationsDetailReport";
import { ReservationsDailyManifestReportReducer } from "./Reservations/ReservationsDailyManifestReport";
import { ReservationsBookingsbyLocationReportReducer } from "./Reservations/ReservationsBookingsbyLocationReport";
import { ReservationsPrepaidBookingsReportReducer } from "./Reservations/ReservationsPrepaidBookingsReport";
import { ReservationsCRXRezpowerReservationsReducer } from "./Reservations/ReservationsCRXRezpowerReservations";
import { userReducer } from "./System/Users";
const rootReducers = combineReducers({
  toastReducer,
  locationReducer,
  vehicleCategoryReducer,
  vehicleMakeReducer,
  vehicleModelReducer,
  vehicleTrimReducer,
  vehicleTransmissionTypeReducer,
  vehicleAcTypeReducer,
  vehicleStereoTypeReducer,
  vehicleVehicleStatusReducer,
  changepasswordReducer,
  vehicleVehicleOperationsReducer,
  vehicleDealershipsReducer,
  vehicleBodyShopReducer,
  vehicleDealershipVehicleDealReducer,
  rateadminRateCodeReducer,
  rateadminRatePlanReducer,
  rateadminRateTypesReducer,
  rateadminLorAdjustmentsReducer,
  rateadminRatesReducer,
  rateadminExtraProductCategoryReducer,
  rateadminExtraProductChargeReducer,
  rateadminNetworksReducer,
  rateadminBlackoutsReducer,
  rateadminRateMirrorsReducer,
  holidaysReducer,
  SettingsSettingWeekendsReducer,
  SettingsPaynowDiscountsReducer,
  SettingsPaynowOptionsReducer,
  RentersRentersInfoReducer,
  RentersRevenueAgreementsReducer,
  RentersNonRevenueAgreementsReducer,
  RentersVehicleInquiryReducer,
  RentersRegisteredRentersReducer,
  RentersLogInteractionsReducer,
  ReservationsReservationsReducer,
  ReservationsDetailReportReducer,
  ReservationsDailyManifestReportReducer,
  ReservationsBookingsbyLocationReportReducer,
  ReservationsPrepaidBookingsReportReducer,
  ReservationsCRXRezpowerReservationsReducer,
  userReducer
});

export default rootReducers;
