import {
  arrayBufferDownload,
  arrayBufferDownloadExcel,
  downloadCSV,
} from "../../../../utils/download";
import {
  decorateEmptyGridValues,
  setColumnDefaultExtraProps,
} from "../../../../utils/table/gridUtils";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_RESERVATIONS_BOOKINGBYLOCATIONREPORT_LIST,
  TOAST,
  FETCH_RESERVATIONS_BOOKINGBYLOCATIONREPORT_INIT,
  FETCH_RESERVATIONS_BOOKINGBYLOCATIONREPORT_DETAIL,
  EXPORT_RESERVATIONS_BOOKINGBYLOCATIONREPORT,
  CREATE_RESERVATIONS_BOOKINGBYLOCATIONREPORT,
  EDIT_RESERVATIONS_BOOKINGBYLOCATIONREPORT,
  INIT_CREATE_RESERVATIONS_BOOKINGBYLOCATIONREPORT_SCREEN,
  RESERVATIONS_BOOKINGBYLOCATIONREPORTDD,
  RESERVATIONS_BOOKINGBYLOCATIONREPORTDELETE,
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
} from "../../../types/Reservations/BookingsbyLocationReport.type";
import { SET_IS_SEARCH } from "../../../types/Reservations/DailyManifestReport.type";

const formFieldValueMap = {
  id: null,
  description: "",
  modBy: "",
  modTime: "",
  status: 1,
  title: "",
  userId: "",
};

const INITIAL_STATE = {
  ReservationsBookingsbyLocationReportListview: [],
  ReservationsBookingsbyLocationReportDD: [],
  isReservationsBookingsbyLocationReportDDSuccess: false,
  isReservationsBookingsbyLocationReportDDError: false,
  isLoading: false,
  isSearch: false,
  error: null,
  formFieldValueMap,
  isReservationsBookingsbyLocationReportCreateSuccess: false,
  isReservationsBookingsbyLocationReportCreateError: false,
  isReservationsBookingsbyLocationReportDetailSuccess: false,
  isReservationsBookingsbyLocationReportDetailError: false,
  isReservationsBookingsbyLocationReportEditSuccess: false,
  isReservationsBookingsbyLocationReportEditError: false,
  isReservationsBookingsbyLocationReportExportSuccess: false,
  isReservationsBookingsbyLocationReportExportError: false,
  isUploadImageSuccess: false,
  isUploadImageError: false,
  imageUploadResponse: null,
  isReservationsBookingsbyLocationReportDeleteSuccess: false,
  isReservationsBookingsbyLocationReportDeleteError: false,
};

const tranformToGridView = (data) => {
  const rows = data.map((item) => {
    const { title, refCode, count } = item;

    const transformedValues = {
      title,
      refCode,
      count
    };
    const decoratedValues = {};
    Object.keys(transformedValues).forEach((key) => {
      decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
    });
    return decoratedValues;
  });

  const columns = [
    {
      colLabel: "ID",
      dataKey: "id",
      extraProps: {
        isSortable: true,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },

    {
      colLabel: "Loc Code",
      dataKey: "refCode",
      extraProps: {
        isSortable: true,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },
    {
      colLabel: "Location Name",
      dataKey: "title",
      extraProps: {
        isSortable: true,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },
    {
      colLabel: "Booking",
      dataKey: "count",
      extraProps: {
        isSortable: true,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },
  ];

  return {
    datas: { columns, rows },
  };
};

export const ReservationsBookingsbyLocationReportReducer = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case FETCH_RESERVATIONS_BOOKINGBYLOCATIONREPORT_INIT:
      return { ...INITIAL_STATE, isLoadingPage: true };
    case FETCH_RESERVATIONS_BOOKINGBYLOCATIONREPORT_DETAIL.START:
    case EXPORT_RESERVATIONS_BOOKINGBYLOCATIONREPORT.START:
    case UPLOAD_IMAGE.START:
    case FETCH_RESERVATIONS_BOOKINGBYLOCATIONREPORT_LIST.START:
    case CREATE_RESERVATIONS_BOOKINGBYLOCATIONREPORT.START:
    case RESERVATIONS_BOOKINGBYLOCATIONREPORTDELETE.START:
    case EDIT_RESERVATIONS_BOOKINGBYLOCATIONREPORT.START:
    case RESERVATIONS_BOOKINGBYLOCATIONREPORTDD.START:
      return { ...state, isLoading: true };

    case FETCH_RESERVATIONS_BOOKINGBYLOCATIONREPORT_LIST.SUCCESS: {
      const { page, data } = action.payload.data;

      let resetState = {
        ReservationsBookingsbyLocationReportListview: [],
        paginationInfo: null,
        error: null,
        isLoading: false,
        isSearch: false,
        isLoadingPage: false,
        isPageLevelError: false,
        isReservationsBookingsbyLocationReportCreateSuccess: false,
        isReservationsBookingsbyLocationReportCreateError: false,
      };
      if (!data) {
        return resetState;
      }
      const { number, size, totalElements, totalPages } = page;
      const { datas } = tranformToGridView(data);
      return {
        ...resetState,
        ReservationsBookingsbyLocationReportListview: datas,
        paginationInfo: {
          pageSize: size,
          totalRecords: totalElements,
          totalPages: totalPages,
          pageNumber: number,
        },
      };
    }
    case FETCH_RESERVATIONS_BOOKINGBYLOCATIONREPORT_LIST.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        ReservationsBookingsbyLocationReportListview: [],
        isLoading: false,
        isLoadingPage: false,
        error,
        isPageLevelError: true,
        isReservationsBookingsbyLocationReportDeletionSuccess: false,
        isReservationsBookingsbyLocationReportDeletionError: false,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }

    case FETCH_RESERVATIONS_BOOKINGBYLOCATIONREPORT_DETAIL.SUCCESS: {
      const temp = action.payload;
      if (action.payload && typeof action.payload === "object") {
        Object.keys(action.payload).forEach((key) => {
          if (formFieldValueMap.hasOwnProperty(key)) {
            temp[key] = action.payload[key];
          }
        });
      }

      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isReservationsBookingsbyLocationReportDetailSuccess: true,
      };
    }
    case FETCH_RESERVATIONS_BOOKINGBYLOCATIONREPORT_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isReservationsBookingsbyLocationReportDetailSuccess: false,
        isReservationsBookingsbyLocationReportDetailError: true,
      };
    }

    //DD
    case RESERVATIONS_BOOKINGBYLOCATIONREPORTDD.SUCCESS: {
      const temp = action.payload;

      return {
        ...state,
        isLoading: false,
        ReservationsBookingsbyLocationReportDD: temp,
        isReservationsBookingsbyLocationReportDDSuccess: true,
        isReservationsBookingsbyLocationReportDDError: false,
      };
    }
    case RESERVATIONS_BOOKINGBYLOCATIONREPORTDD.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isReservationsBookingsbyLocationReportDDSuccess: false,
        isReservationsBookingsbyLocationReportDDError: true,
      };
    }
    //END DD
    //DELETE

    case SET_DELETE_CONFIRMATION_POPUP_VISIBILITY: {
      const shouldOpenDeleteConfirmationPopup = action.payload;
      return {
        ...state,
        shouldOpenDeleteConfirmationPopup,
        isReservationsBookingsbyLocationReportDeleteSuccess: false,
        isReservationsBookingsbyLocationReportDeleteError: false,
      };
    }
    case RESERVATIONS_BOOKINGBYLOCATIONREPORTDELETE.SUCCESS: {
      const temp = action.payload;

      return {
        ...state,
        isLoading: false,
        isReservationsBookingsbyLocationReportDeleteSuccess: true,
        isReservationsBookingsbyLocationReportDeleteError: false,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }
    case RESERVATIONS_BOOKINGBYLOCATIONREPORTDELETE.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isReservationsBookingsbyLocationReportDeleteSuccess: false,
        isReservationsBookingsbyLocationReportDeleteError: true,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }
    //END DD

    // UPLOAD_IMAGE Start
    case UPLOAD_IMAGE.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        isLoading: false,
        isLoadingPage: false,
        error,
        isUploadImageSuccess: false,
        isUploadImageError: true,
        shouldOpenExportConfirmationPopup: false,
      };
    }

    case UPLOAD_IMAGE.SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        imageUploadResponse: data,
        isUploadImageSuccess: true,
        isUploadImageError: false,
        shouldOpenExportConfirmationPopup: false,
      };
    }

    // UPLOAD_IMAGE End

    // Export Start
    case EXPORT_RESERVATIONS_BOOKINGBYLOCATIONREPORT.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        isLoading: false,
        isLoadingPage: false,
        error,
        isReservationsBookingsbyLocationReportExportSuccess: false,
        isReservationsBookingsbyLocationReportExportError: true,
        shouldOpenExportConfirmationPopup: false,
      };
    }

    case EXPORT_RESERVATIONS_BOOKINGBYLOCATIONREPORT.SUCCESS: {
      const data = action.payload;
      arrayBufferDownloadExcel(data, "bookings-by-location-report.xls");
      return {
        ...state,
        isReservationsBookingsbyLocationReportExportSuccess: true,
        isReservationsBookingsbyLocationReportExportError: false,
        shouldOpenExportConfirmationPopup: false,
      };
    }

    // Export End

    // EDIT
    case EDIT_RESERVATIONS_BOOKINGBYLOCATIONREPORT.ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isLoading: false,
        isReservationsBookingsbyLocationReportEditSuccess: false,
        isReservationsBookingsbyLocationReportEditError: true,
      };
    }

    case EDIT_RESERVATIONS_BOOKINGBYLOCATIONREPORT.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isReservationsBookingsbyLocationReportEditSuccess: true,
        isReservationsBookingsbyLocationReportEditError: false,
      };
    }

    // EDIT END

    // Create
    case INIT_CREATE_RESERVATIONS_BOOKINGBYLOCATIONREPORT_SCREEN: {
      return { ...INITIAL_STATE };
    }

    case CREATE_RESERVATIONS_BOOKINGBYLOCATIONREPORT.ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isLoading: false,
        isReservationsBookingsbyLocationReportCreateSuccess: false,
        isReservationsBookingsbyLocationReportCreateError: true,
      };
    }
    case CREATE_RESERVATIONS_BOOKINGBYLOCATIONREPORT.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isReservationsBookingsbyLocationReportCreateSuccess: true,
        isReservationsBookingsbyLocationReportCreateError: false,
      };
    }

    case SET_IS_SEARCH: {
      return {
        ...state,
        isSearch: action.payload,
      };
    }

    //End
    //End

    case TOAST.RESET: {
      return {
        ...state,
        isReservationsBookingsbyLocationReportCreateSuccess: false,
        isReservationsBookingsbyLocationReportCreateError: false,
        isReservationsBookingsbyLocationReportEditError: false,
        isReservationsBookingsbyLocationReportEditSuccess: false,
        isReservationsBookingsbyLocationReportDetailError: false,
      };
    }
    default:
      return state;
  }
};
