import React from "react";
import TimeAndBilling from "../../../component/Financial/TimeAndBilling";

const TimeAndBillingScreen = () => {
  return (
    <div>
      <TimeAndBilling />
    </div>
  );
};

export default TimeAndBillingScreen;
