import React from "react";
import CorporateMeetings from "../../../component/Admin/CorporateMeetings";

const CorporateMeetingsScreen = () => {
  return (
    <div>
      <CorporateMeetings />
    </div>
  );
};

export default CorporateMeetingsScreen;
