export let baseURL = window.BASE_URL;
if(window.APP_ROOT === "same") {
  baseURL = window.location.origin + "/api/";
}

export const API_URL ={
  login:"auth/login",
  forgot:"auth/password/forgot",
  resetpwd:"auth/password/reset",
  changepassword:"resetPassword",
  LOCATION:{
      GET:"getAllLocation",
      CREATE:"saveLocation",
      EDIT:"updateLocation",
      GET_BY_ID:"getLocation?locationId=:id",
      DELETE:"",
      GETCATEGORY:"getCategory",
      EXPORT:"downloadLocationsExcel",
      MODELDROPDOWN:"getDropDownVehicleLocation"
  },
  VEHICLE_CATEGORY:{
    GET:"getAllVehicleCategory",
    CREATE:"saveVehicleCategory",
    EDIT:"updateVehicleCategory",
    GET_BY_ID:"getVehicleCategory?categoryId=:id",
    DELETE:"",
    GETCATEGORY:"getCategory",
    EXPORT:"downloadCategoryExcel",
    MODELDROPDOWN:"getDropDownVehicleCategory"
},
UPLOAD_IMAGE:"documentUpload?userId=:id",
VEHICLE_MAKE:{
    GET:"getAllVehicleMake",
    CREATE:"saveVehicleMake",
    EDIT:"updateVehicleMake",
    GET_BY_ID:"getVehicleMake?makeId=:id",
    DELETE:"",
    EXPORT:"downloadMakeExcel",
    MAKEDROPDOWN:"getDropDownVehicleMake"
},
VEHICLE_MODEL:{
  GET:"getAllVehicleModel",
  CREATE:"saveVehicleModel",
  EDIT:"updateVehicleModel",
  GET_BY_ID:"getVehicleModel?modelId=:id",
  DELETE:"",
  EXPORT:"downloadModelExcel",
  MAKEDROPDOWN:"getDropDownVehicleModel"
},
VEHICLE_TRIM:{
  GET:"getAllVehicleTrim",
  CREATE:"saveVehicleTrim",
  EDIT:"updateVehicleTrim",
  GET_BY_ID:"getVehicleTrim?trimId=:id",
  DELETE:"",
  EXPORT:"downloadTrimExcel",
  MODELDROPDOWN:"getDropDownVehicleTrim"
},
VEHICLE_TRANSMISSIONTYPE:{
  GET:"getAllVehicleTransmissionType",
  CREATE:"saveVehicleTransmissionType",
  EDIT:"updateVehicleTransmissionType",
  GET_BY_ID:"getVehicleTransmissionType?transMissionId=:id",
  DELETE:"",
  EXPORT:"downloadTransmissionTypeExcel",
  MODELDROPDOWN:"getDropDownVehicleModel"
},
VEHICLE_ACTYPE:{
  GET:"getAllVehicleAcType",
  CREATE:"saveVehicleAcType",
  EDIT:"updateVehicleAcType",
  GET_BY_ID:"getVehicleAcType?acTypeId=:id",
  DELETE:"",
  EXPORT:"downloadAcTypeExcel",
  MODELDROPDOWN:"downloadAcTypeExcel"
},
VEHICLE_STEREOTYPE:{
  GET:"getAllVehicleStereoType",
  CREATE:"saveVehicleStereoType",
  EDIT:"updateVehicleStereoType",
  GET_BY_ID:"getVehicleStereoType?stereoTypeId=:id",
  DELETE:"",
  EXPORT:"downloadStereoTypeExcel",
  MODELDROPDOWN:"getDropDownVehicleModel"
},

VEHICLE_VEHICLESTATUS:{
  GET:"getAllVehicleStatus",
  CREATE:"saveVehicleStatus",
  EDIT:"updateVehicleStatus",
  GET_BY_ID:"getVehicleStatus?statusId=:id",
  DELETE:"",
  EXPORT:"downloadStatusExcel",
  MODELDROPDOWN:"getDropDownVehicleStatus"
},
VEHICLE_VEHICLEOPERATIONS:{
  GET:"getAllVehicleOperation",
  CREATE:"saveVehicleOperation",
  EDIT:"updateVehicleOperation",
  GET_BY_ID:"getVehicleOperation?vehicleId=:id",
  DELETE:"",
  EXPORT:"downloadOperationExcel",
  MODELDROPDOWN:"getDropDownVehicleModel"
},
VEHICLE_DEALERSHIPS:{
  GET:"getAllVehicleDealerShip",
  CREATE:"saveVehicleDealerShip",
  EDIT:"updateVehicleDealerShip",
  GET_BY_ID:"getVehicleDealerShip?dealerShipId=:id",
  DELETE:"",
  EXPORT:"downloadVehicleDealerShipExcel",
  MODELDROPDOWN:"getDropDownVehicleSupplier"
},
VEHICLE_BODYSHOP:{
  GET:"getAllVehicleBodyRepair",
  CREATE:"saveVehicleBodyRepairShop",
  EDIT:"updateVehicleBodyRepairShop",
  GET_BY_ID:"getVehicleBodyRepairShop?bodyRepairId=:id",
  DELETE:"",
  EXPORT:"downloadVehicleBodyRepairExcel",
  MODELDROPDOWN:"getDropDownVehicleModel"
},
VEHICLE_DEALERSHIPVEHICLEDEAL:{
  GET:"getAllVehicleDealerShipDeals",
  CREATE:"saveVehicleDealerShipDeals",
  EDIT:"updateVehicleDealerShipDeals",
  GET_BY_ID:"getVehicleDealerShipDeals?DealerShipDealsId=:id",
  DELETE:"",
  EXPORT:"downloadVehicleDealerShipDealsExcel",
  MODELDROPDOWN:"getDropDownVehicleModel"
},

RATEADMIN_RATE_CODE:{
  GET:"getAllRateCode",
  CREATE:"saveRateCode",
  EDIT:"updateRateCodeType",
  GET_BY_ID:"getRateCode?rateCodeId=:id",
  DELETE:"",
  EXPORT:"downloadRateCodeExcel",
  DROPDOWN:"getDropDownRateCode"
},
RATEADMIN_RATE_PLAN:{
  GET:"getAllRatePlan",
  CREATE:"saveRatePlan",
  EDIT:"updateRatePlanType",
  GET_BY_ID:"getRatePlan?ratePlanId=:id",
  DELETE:"",
  EXPORT:"downloadRatePlanExcel",
  DROPDOWN:"getDropDownRatePlan"
},

RATEADMIN_RATE_TYPES:{
  GET:"getAllRateType",
  CREATE:"saveRateType",
  EDIT:"updateRateTypeType",
  GET_BY_ID:"getRateType?rateTypeId=:id",
  DELETE:"",
  EXPORT:"downloadRateTypeExcel",
  MODELDROPDOWN:"getDropDownRateType"
},

RATEADMIN_LOR_ADJUSTMENTS:{
  GET:"getAllLorAdjustments",
  CREATE:"saveLorAdjustments",
  EDIT:"updateLorAdjustments",
  GET_BY_ID:"getLorAdjustments?lorAdjustmentsId=:id",
  DELETE:"",
  EXPORT:"downloadLorAdjustmentsExcel",
  MODELDROPDOWN:"getDropDownRateType"
},
RATEADMIN_RATES:{
  GET:"getAllRates",
  CREATE:"saveRate",
  EDIT:"updateRate",
  GET_BY_ID:"getRate?rateId=:id",
  DELETE:"deleteRate?rateId=:id",
  EXPORT:"downloadRateExcel",
  DROPDOWN:"getDropDownRatePlan",
  RATEOPTIONDD:"getDropDownRateOption",
  RATENETWORKDD:"getDropDownNetwork",
  SAVERATEADJUST:"saveRateAdjustment",
  GETRATEADJUST:"getRateAdjustment?rateId=:id",
  GETRATETEST:"rateTest"
},

RATEADMIN_EXTRAPRODUCTCATEGORY:{
  GET:"getAllExtraProductCategory",
  CREATE:"saveRateExtraProductCategory",
  EDIT:"updateRateExtraProductCategory",
  GET_BY_ID:"getRateExtraProductCategory?extraCategoryId=:id",
  DELETE:"",
  EXPORT:"downloadRateExtraProductCategoryExcel",
  DROPDOWN:"getDropDownRateExtraProductCategory"
},
RATEADMIN_EXTRAPRODUCTCHARGE:{
  GET:"getAllRateExtraProdCharge",
  CREATE:"saveRateExtraProdCharge",
  EDIT:"updateRateExtraProdCharge",
  GET_BY_ID:"getRateExtraProdCharge?extraProdId=:id",
  DELETE:"",
  EXPORT:"downloadExtraProdCharge",
  DROPDOWN:"getDropDownRateType"
},
RATEADMIN_NETWORKS:{
  GET:"getAllNetwork",
  CREATE:"saveNetwork",
  EDIT:"updateNetwork",
  GET_BY_ID:"getNetwork?extraProdId=:id",
  DELETE:"",
  EXPORT:"downloadNetworkExcel",
  DROPDOWN:"getDropDownNetwork"
},
RATEADMIN_BLACKOUTS:{
  GET:"getAllBlackout",
  CREATE:"saveBlackout",
  EDIT:"updateBlackout",
  GET_BY_ID:"getBlackOut?blackOutId=:id",
  DELETE:"",
  EXPORT:"downloadBlackOutExcel",
  DROPDOWN:"getDropDownNetwork"
},
RATEADMIN_RATEMIRRORS:{
  GET:"getAllRateMirror",
  CREATE:"saveRateMirror",
  EDIT:"updateRateMirror",
  GET_BY_ID:"getRateMirror?rateMirrorId=:id",
  DELETE:"deleteRateMirror?rateMirrorId=:id",
  EXPORT:"downloadRateMirrorExcel",
  DROPDOWN:"getDropDownRateMirror"
},
HOLIDAYS:{
  GET:"getAllHoliday",
  CREATE:"saveHoliday",
  EDIT:"updateHoliday",
  GET_BY_ID:"getHoliday?holidayId=:id",
  DELETE:"deleteRateMirror?rateMirrorId=:id",
  EXPORT:"downloadHolidayExcel",
  DROPDOWN:"getDropDownRateMirror"
},
SETTINGS_SETTINGWEEKENDS:{
  GET:"getAllDaysOfWeek",
  CREATE:"saveDaysOfWeek",
  EDIT:"updateDaysOfWeek",
  GET_BY_ID:"getDaysOfWeek?daysOfWeekId=:id",
  DELETE:"deleteRateMirror?rateMirrorId=:id",
  EXPORT:"downloadDaysOfWeek",
  DROPDOWN:"getDropDownRateMirror"
},
SETTINGS_PAYNOWDISCOUNTS:{
  GET:"getAllSettingPayNowDisCount",
  CREATE:"savePayNowDisCount",
  EDIT:"updatePayNowDisCount",
  GET_BY_ID:"getPayNowDisCount?payNowDisCountId=:id",
  DELETE:"deleteRateMirror?rateMirrorId=:id",
  EXPORT:"downloadPayNowDisCount",
  DROPDOWN:"getDropDownRateMirror"
},
SETTINGS_PAYNOWOPTIONS:{
  GET:"getAllSettingPayOption",
  CREATE:"savePayOption",
  EDIT:"updatePayOption",
  GET_BY_ID:"getPayOption?payOptionId=:id",
  DELETE:"deletePayOption?id=:id",
  EXPORT:"downloadPayOption",
  DROPDOWN:"getDropDownRateMirror"
},
RENTERS_RENTERSINFO:{
  GET:"getAllRenterInfo",
  CREATE:"saveRateMirror",
  EDIT:"updateRateMirror",
  GET_BY_ID:"getRateMirror?rateMirrorId=:id",
  DELETE:"deleteRateMirror?rateMirrorId=:id",
  EXPORT:"downloadRenterInfoExcel",
  DROPDOWN:"getDropDownRateMirror",
  EXPORTPDF:"downloadRenterInfoPdf",
  EXPORTCSV:"downloadRenterInfoCsv",
},
RENTERS_REVENUEAGREEMENTS:{
  GET:"getAllRateMirror",
  CREATE:"saveRateMirror",
  EDIT:"updateRateMirror",
  GET_BY_ID:"getRateMirror?rateMirrorId=:id",
  DELETE:"deleteRateMirror?rateMirrorId=:id",
  EXPORT:"downloadRateMirrorExcel",
  DROPDOWN:"getDropDownRateMirror",
  EXISTINGDATA:"getDropDownNetwork"
},
RENTERS_NONREVENUEAGREEMENTS:{
  GET:"getAllRateMirror",
  CREATE:"saveRateMirror",
  EDIT:"updateRateMirror",
  GET_BY_ID:"getRateMirror?rateMirrorId=:id",
  DELETE:"deleteRateMirror?rateMirrorId=:id",
  EXPORT:"downloadRateMirrorExcel",
  DROPDOWN:"getDropDownRateMirror"
},
RENTERS_VEHICLEINQUIRY:{
  GET:"getAllRenterVehicleEnquire",
  CREATE:"sendVehicleEnquireMail",
  EDIT:"sendVehicleEnquireMail",
  GET_BY_ID:"getRenterVehicleInfo?id=:id",
  DELETE:"deleteRateMirror?rateMirrorId=:id",
  EXPORT:"downloadRenterVehicleEnquireExcel",
  DROPDOWN:"getDropDownRateMirror",
  EXPORTPDF:"downloadRenterInfoPdf",
  EXPORTCSV:"downloadRenterVehicleEnquireCsv",
},
RENTERS_REGISTEREDRENTERS:{
  GET:"getAllRegisteredRenterInfo",
  CREATE:"saveRateMirror",
  EDIT:"updatePaymentStatus",
  VIEW_BY_ID:"getReservationByRenterId",
  GET_BY_ID:"renterDetails?reservationId=:id",
  DELETE:"deleteRateMirror?rateMirrorId=:id",
  EXPORT:"downloadRegisteredRenterInfoExcel",
  DROPDOWN:"getDropDownRateMirror",
  EXPORTPDF:"downloadRegisteredRenterInfoPdf",
  EXPORTCSV:"downloadRegisteredRenterInfoCsv",
},
RENTERS_LOGINTERACTIONS:{
  GET:"getAllRateMirror",
  CREATE:"saveRateMirror",
  EDIT:"updateRateMirror",
  GET_BY_ID:"getRateMirror?rateMirrorId=:id",
  DELETE:"deleteRateMirror?rateMirrorId=:id",
  EXPORT:"downloadRateMirrorExcel",
  DROPDOWN:"getDropDownRateMirror"
},
RESERVATIONS_RESERVATIONS:{
  GET:"getAllReservation",
  CREATE:"saveReservation",
  EDIT:"updateRateMirror",
  GET_BY_ID:"getRateMirror?rateMirrorId=:id",
  DELETE:"deleteRateMirror?rateMirrorId=:id",
  EXPORT:"downloadRateMirrorExcel",
  DROPDOWN:"getDropDownRateMirror",
  SEARCH:"reservationSearch"
},
RESERVATIONS_DETAILREPORT:{
  GET:"getAllDailyReservation",
  CREATE:"saveRateMirror",
  EDIT:"updateRateMirror",
  GET_BY_ID:"getRateMirror?rateMirrorId=:id",
  DELETE:"deleteRateMirror?rateMirrorId=:id",
  EXPORT:"downloadDailyReservationExcel",
  DROPDOWN:"getDropDownRateMirror"
},
RESERVATIONS_DAILYMANIFESTREPORT:{
  GET:"getAllReservationDailyManifest",
  CREATE:"saveRateMirror",
  EDIT:"updateRateMirror",
  GET_BY_ID:"getRateMirror?rateMirrorId=:id",
  DELETE:"deleteRateMirror?rateMirrorId=:id",
  EXPORT:"downloadDailyReservationManifestExcel",
  DROPDOWN:"getDropDownRateMirror"
},
RESERVATIONS_BOOKINGBYLOCATIONREPORT:{
  GET:"getAllBookingByLocation",
  CREATE:"saveRateMirror",
  EDIT:"updateRateMirror",
  GET_BY_ID:"getRateMirror?rateMirrorId=:id",
  DELETE:"deleteRateMirror?rateMirrorId=:id",
  EXPORT:"downloadBookingByLocationExcel",
  DROPDOWN:"getDropDownRateMirror"
},
RESERVATIONS_PREPAIDBOOKINGSREPORT:{
  GET:"getAllRateMirror",
  CREATE:"saveRateMirror",
  EDIT:"updateRateMirror",
  GET_BY_ID:"getRateMirror?rateMirrorId=:id",
  DELETE:"deleteRateMirror?rateMirrorId=:id",
  EXPORT:"downloadRateMirrorExcel",
  DROPDOWN:"getDropDownRateMirror"
},
RESERVATIONS_CRXREZPOWERRESERVATIONS:{
  GET:"getAllRateMirror",
  CREATE:"saveRateMirror",
  EDIT:"updateRateMirror",
  GET_BY_ID:"getRateMirror?rateMirrorId=:id",
  DELETE:"deleteRateMirror?rateMirrorId=:id",
  EXPORT:"downloadRateMirrorExcel",
  DROPDOWN:"getDropDownRateMirror"
},
SYSTEM_USERS:{
  GET:"users/getUsers",
  CREATE:"saveUsers",
  EDIT:"updateUsers",
  GET_BY_ID:"getUsers?UsersId=:id",
  DELETE:"deleteUsers?UsersId=:id",
  EXPORT:"downloadUsersExcel",
  DROPDOWN:"getDropDownRateMirror"
},
SYSTEM_USERS:{
  GET:"users/getUsers",
  CREATE:"saveUsers",
  EDIT:"updateUsers",
  GET_BY_ID:"getUsers?UsersId=:id",
  DELETE:"deleteUsers?UsersId=:id",
  EXPORT:"downloadUsersExcel",
  DROPDOWN:"getDropDownRateMirror"
},
}


export const APP_URLS ={
  CHANGEPASSWORD:"/chagne-password",
   LOCATION:{
    HOME:"/location",
    CREATE:"/createlocation",
    EDIT:"/locations/:id/edit"
   },
   VEHICLE_CATEGORY:{
    HOME:"/vehicle-category",
    CREATE:"/create-vehicle-category",
    EDIT:"/create-vehicle-category/:id/edit"
   },
   VEHICLE_MAKE:{
    HOME:"/vehicle-make",
    CREATE:"/create-vehicle-make",
    EDIT:"/create-vehicle-make/:id/edit"
   },
   VEHICLE_MODEL:{
    HOME:"/vehicle-model",
    CREATE:"/create-vehicle-model",
    EDIT:"/create-vehicle-model/:id/edit"
   },
   VEHICLE_TRIM:{
    HOME:"/vehicle-trim",
    CREATE:"/create-vehicle-trim",
    EDIT:"/create-vehicle-trim/:id/edit"
   },
   VEHICLE_TRANSMISSIONTYPE:{
    HOME:"/vehicle-transmission-type",
    CREATE:"/create-vehicle-transmission-type",
    EDIT:"/create-vehicle-transmission-type/:id/edit"
   },
   VEHICLE_ACTYPE:{
    HOME:"/vehicle-ac-type",
    CREATE:"/create-vehicle-ac-type",
    EDIT:"/create-vehicle-ac-type/:id/edit"
   },
   VEHICLE_STEREOTYPE:{
    HOME:"/vehicle-stereo-type",
    CREATE:"/create-vehicle-stereo-type",
    EDIT:"/create-vehicle-stereo-type/:id/edit"
   },
   VEHICLE_VEHICLESTATUS:{
    HOME:"/vehicle-status",
    CREATE:"/create-status",
    EDIT:"/create-status/:id/edit"
   },
   VEHICLE_VEHICLEOPERATIONS:{
    HOME:"/vehicle-operations",
    CREATE:"/create-vehicle-operations",
    EDIT:"/create-vehicle-operations/:id/edit"
   },
   VEHICLE_DEALERSHIPS:{
    HOME:"/vehicle-dealerships",
    CREATE:"/create-dealerships",
    EDIT:"/create-dealerships/:id/edit"
   },
   
   VEHICLE_BODYSHOP:{
    HOME:"/vehicle-body-shop",
    CREATE:"/create-body-shop",
    EDIT:"/create-body-shop/:id/edit"
   },
   VEHICLE_DEALERSHIPVEHICLEDEAL:{
    HOME:"/vehicle-dealership-vehicle-deal",
    CREATE:"/create-dealership-vehicle-deal",
    EDIT:"/create-dealership-vehicle-deal/:id/edit"
   },
   RATEADMIN_RATE_CODE:{
    HOME:"/rate-code",
    CREATE:"/create-rate-code",
    EDIT:"/create-rate-code/:id/edit"
   },
   RATEADMIN_RATE_PLAN:{
    HOME:"/rate-plans",
    CREATE:"/create-rate-plan",
    EDIT:"/create-rate-plan/:id/edit"
   },
   RATEADMIN_RATE_TYPES:{
    HOME:"/rate-types",
    CREATE:"/create-rate-types",
    EDIT:"/create-rate-types/:id/edit"
   },
   RATEADMIN_LOR_ADJUSTMENTS:{
    HOME:"/lor-adjustments",
    CREATE:"/create-lor-adjustments",
    EDIT:"/create-lor-adjustments/:id/edit"
   },
   RATEADMIN_RATES:{
    HOME:"/rates",
    CREATE:"/create-rates",
    EDIT:"/create-rates/:id/edit",
    COPY:"/create-rates/:id/copy"
   },
   RATEADMIN_EXTRAPRODUCTCATEGORY:{
    HOME:"/extra-product-category",
    CREATE:"/create-extra-product-category",
    EDIT:"/create-extra-product-category/:id/edit",
   },
   RATEADMIN_EXTRAPRODUCTCHARGE:{
    HOME:"/extra-product-charge",
    CREATE:"/create-extra-product-charge",
    EDIT:"/create-extra-product-charge/:id/edit",
    COPY:"/create-extra-product-charge/:id/copy"
   },
   RATEADMIN_NETWORK:{
    HOME:"/networks",
    CREATE:"/create-networks",
    EDIT:"/create-networks/:id/edit",
   },
   RATEADMIN_BLACKOUTS:{
    HOME:"/blackouts",
    CREATE:"/create-blackouts",
    EDIT:"/create-blackouts/:id/edit",
   },
   RATEADMIN_RATEMIRRORS:{
    HOME:"/rate-mirrors",
    CREATE:"/create-rate-mirrors",
    EDIT:"/create-rate-mirrors/:id/edit",
    HOME:"/rate-mirrors",
    CREATE:"/create-rate-mirrors",
    EDIT:"/create-rate-mirrors/:id/edit",
   },
   HOLIDAYS:{
    HOME:"/holidays",
    CREATE:"/create-holidays",
    EDIT:"/create-holidays/:id/edit",
   },
   SETTINGS_SETTINGWEEKENDS:{
    HOME:"/days-of-week",
    CREATE:"/create-days-of-week",
    EDIT:"/create-days-of-week/:id/edit",
   },
   SETTINGS_PAYNOWDISCOUNTS:{
    HOME:"/pay-now-discount",
    CREATE:"/create-pay-now-discount",
    EDIT:"/create-pay-now-discount/:id/edit",
   },
   SETTINGS_PAYNOWOPTIONS:{
    HOME:"/pay-now-option",
    CREATE:"/create-pay-now-option",
    EDIT:"/create-pay-now-option/:id/edit",
   },
   RENTERS_RENTERSINFO:{
    HOME:"/renters-info",
    CREATE:"/create-renters-info",
    EDIT:"/create-renters-info/:id/edit",
   },
   RENTERS_REVENUEAGREEMENTS:{
    HOME:"/revenue-agreements",
    CREATE:"/create-revenue-agreements",
    EDIT:"/create-revenue-agreements/:id/edit",
   },
   RENTERS_NONREVENUEAGREEMENTS:{
    HOME:"/non-revenue-agreements",
    CREATE:"/create-non-revenue-agreements",
    EDIT:"/create-non-revenue-agreements/:id/edit",
   },
   RENTERS_VEHICLEINQUIRY:{
    HOME:"/vehicle-inquiries",
    CREATE:"/create-vehicle-inquiries",
    EDIT:"/create-vehicle-inquiries/:id/edit",
   },
   RENTERS_REGISTEREDRENTERS:{
    HOME:"/registered-renters",
    CREATE:"/create-registered-renters",
    VIEW:"/view-registered-renters/:id/view",
    EDIT:"/create-registered-renters/:id/edit",
   },
   RENTERS_LOGINTERACTIONS:{
    HOME:"/renter-interaction-log",
    CREATE:"/create-renter-interaction-log",
    EDIT:"/create-renter-interaction-log/:id/edit",
    HOME:"/renter-interaction-log",
    CREATE:"/create-renter-interaction-log",
    EDIT:"/create-renter-interaction-log/:id/edit",
   },
   RESERVATIONS_RESERVATIONS:{
    HOME:"/reservations",
    CREATE:"/create-reservations",
    EDIT:"/create-reservations/:id/edit",
   },
   RESERVATIONS_DETAILREPORT:{
    HOME:"/reservation-detail-report",
    CREATE:"/create-reservation-detail-report",
    EDIT:"/create-reservation-detail-report/:id/edit",
    HOME:"/reservation-detail-report",
    CREATE:"/create-reservation-detail-report",
    EDIT:"/create-reservation-detail-report/:id/edit",
   },
   RESERVATIONS_DAILYMANIFESTREPORT:{
    HOME:"/daily-manifest-report",
    CREATE:"/create-daily-manifest-report",
    EDIT:"/create-daily-manifest-report/:id/edit",
   },
   RESERVATIONS_BOOKINGBYLOCATIONREPORT:{
    HOME:"/bookings-by-location",
    CREATE:"/create-bookings-by-location",
    EDIT:"/create-bookings-by-location/:id/edit",
    HOME:"/bookings-by-location",
    CREATE:"/create-bookings-by-location",
    EDIT:"/create-bookings-by-location/:id/edit",
   },
   RESERVATIONS_PREPAIDBOOKINGSREPORT:{
    HOME:"/prepaid-bookings",
    CREATE:"/create-prepaid-bookings",
    EDIT:"/create-prepaid-bookings/:id/edit",
    HOME:"/prepaid-bookings",
    CREATE:"/create-prepaid-bookings",
    EDIT:"/create-prepaid-bookings/:id/edit",
   },
   RESERVATIONS_CRXREZPOWERRESERVATIONS:{
    HOME:"/crx-rezp-reservations",
    CREATE:"/create-crx-rezp-reservations",
    EDIT:"/create-crx-rezp-reservations/:id/edit",
   },
   
   WEBSITE_BLOGS: {
    HOME:"/blogs",
    CREATE:"/create-blogs",
    EDIT:"/blogs/:id/edit",
   },
   WEBSITE_PROMOTION: {
    HOME:"/promotion",
    CREATE:"/create-promotion",
    EDIT:"/promotion/:id/edit",
   },
   WEBSITE_TRAFFIC_LOGS: {
    HOME:"/traffic-logs",
    CREATE:"/create-traffic-logs",
    EDIT:"/traffic-logs/:id/edit",
   },

   OPERATIONS_CAREERS: {
    HOME:"/careers",
    CREATE:"/create-careers",
    EDIT:"/careers/:id/edit",
   },
   OPERATIONS_FORMS: {
    HOME:"/forms",
    CREATE:"/create-forms",
    EDIT:"/forms/:id/edit",
   },
   OPERATIONS_NEWSLETTERS: {
    HOME:"/newsletters",
    CREATE:"/create-newsletters",
    EDIT:"/newsletters/:id/edit",
   },
   OPERATIONS_STAFF_MANAGEMENT: {
    HOME:"/staff-management",
    CREATE:"/create-staff-management",
    EDIT:"/staff-management/:id/edit",
   },

   RESERVATIONS_QUOTES: {
    HOME:"/quotes",
    CREATE:"/create-quotes",
    EDIT:"/quotes/:id/edit",
   },

   RESERVATIONS_BIDS: {
    HOME:"/bids",
    CREATE:"/create-bids",
    EDIT:"/bids/:id/edit",
   },

   RESERVATIONS_TRANSACTIONS: {
    HOME:"/transactions",
    CREATE:"/create-transactions",
    EDIT:"/transactions/:id/edit",
   },

   TASK_ASSIGNMENT_CALENDAR: {
    HOME:"/calendar",
    CREATE:"/create-calendar",
    EDIT:"/calendar/:id/edit",
   },

   TASK_ASSIGNMENT_RECURRING_TO_DO_LIST: {
    HOME:"/recurring-to-do-list",
    CREATE:"/create-recurring-to-do-list",
    EDIT:"/recurring-to-do-list/:id/edit",
   },

   TASK_ASSIGNMENT_TASK_MANAGEMENT_SYSTEM: {
    HOME:"/task-management-system",
    CREATE:"/create-task-management-system",
    EDIT:"/task-management-system/:id/edit",
   },

   ADMIN_CORPORATE_MEETINGS: {
    HOME:"/corporate-meetings",
    CREATE:"/create-corporate-meetings",
    EDIT:"/corporate-meetings/:id/edit",
   },

   ADMIN_LEAVE_APPLICATIONS: {
    HOME:"/leave-applications",
    CREATE:"/create-leave-applications",
    EDIT:"/leave-applications/:id/edit",
   },

   FINANCIAL_A_R: {
    HOME:"/accounts-receivable",
    CREATE:"/create-accounts-receivable",
    EDIT:"/accounts-receivable/:id/edit",
   },
   FINANCIAL_INVOICING: {
    HOME:"/invoicing",
    CREATE:"/create-invoicing",
    EDIT:"/invoicing/:id/edit",
   },
   FINANCIAL_VENDORS: {
    HOME:"/vendors",
    CREATE:"/create-vendors",
    EDIT:"/vendors/:id/edit",
   },
   FINANCIAL_WHOLESALE_BILL_OF_SALE: {
    HOME:"/wholesale-bill-of-sale",
    CREATE:"/create-wholesale-bill-of-sale",
    EDIT:"/wholesale-bill-of-sale/:id/edit",
   },
   FINANCIAL_MISC_UPLOADS: {
    HOME:"/misc-uploads",
    CREATE:"/create-misc-uploads",
    EDIT:"/misc-uploads/:id/edit",
   },
   FINANCIAL_RENTAL_CHECKLIST: {
    HOME:"/rental-checklist",
    CREATE:"/create-rental-checklist",
    EDIT:"/rental-checklist/:id/edit",
   },
   FINANCIAL_TIME_BILLINGS: {
    HOME:"/time-and-billing",
    CREATE:"/create-time-and-billing",
    EDIT:"/time-and-billing/:id/edit",
   },

   COMMUNICATION_SEND_BULK_EMAIL: {
    HOME:"/send-bulk-email",
    CREATE:"/create-send-bulk-email",
    EDIT:"/send-bulk-email/:id/edit",
   },
   COMMUNICATION_SEND_NOTIFICATIONS: {
    HOME:"/send-notifications",
    CREATE:"/create-send-notifications",
    EDIT:"/send-notifications/:id/edit",
   },
   COMMUNICATION_SEND_BULK_SMS: {
    HOME:"/send-bulk-sms",
    CREATE:"/create-send-bulk-sms",
    EDIT:"/send-bulk-sms/:id/edit",
   },
   COMMUNICATION_STAFF: {
    HOME:"/staff",
    CREATE:"/create-staff",
    EDIT:"/staff/:id/edit",
   },

   REPORTS_BRANCH_SUMMARY: {
    HOME:"/branch-summary",
    CREATE:"/create-branch-summary",
    EDIT:"/branch-summary/:id/edit",
   },
   REPORTS_DAILY_BOOKINGS_STATISTICS: {
    HOME:"/daily-bookings-statistics",
    CREATE:"/create-daily-bookings-statistics",
    EDIT:"/daily-bookings-statistics/:id/edit",
   },
   REPORTS_DAILY_DROPOFF_MANIFEST: {
    HOME:"/daily-dropoff-manifest",
    CREATE:"/create-daily-dropoff-manifest",
    EDIT:"/daily-dropoff-manifest/:id/edit",
   },
   REPORTS_NO_SHOW_MANIFEST: {
    HOME:"/no-show-manifest",
    CREATE:"/create-no-show-manifest",
    EDIT:"/no-show-manifest/:id/edit",
   },
   REPORTS_PAY_ON_ARRIVAL_BOOKINGS: {
    HOME:"/pay-on-arrival-bookings",
    CREATE:"/create-pay-on-arrival-bookings",
    EDIT:"/pay-on-arrival-bookings/:id/edit",
   },

   MAINTENANCE_CATEGORYS: {
    HOME:"/maintenance-category",
    CREATE:"/create-maintenance-category",
    EDIT:"/maintenance-category/:id/edit",
   },
   MAINTENANCE_RECORDS: {
    HOME:"/maintenance-records",
    CREATE:"/create-maintenance-records",
    EDIT:"/maintenance-records/:id/edit",
   },

   CLAIMS_COST_CATEGORY: {
    HOME:"/claims-cost-category",
    CREATE:"/create-claims-cost-category",
    EDIT:"/claims-cost-category/:id/edit",
   },
   CAIMS: {
    HOME:"/claims",
    CREATE:"/create-claims",
    EDIT:"/claims/:id/edit",
   },

   COUNTER_MANAGER_BODY_REPAIR_SHOPS: {
    HOME:"/body-repair-shops",
    CREATE:"/create-body-repair-shops",
    EDIT:"/body-repair-shops/:id/edit",
   },
   COUNTER_MANAGER_BROADCAST_MESSAGES: {
    HOME:"/broadcast-messages",
    CREATE:"/create-broadcast-messages",
    EDIT:"/broadcast-messages/:id/edit",
   },
   COUNTER_MANAGER_NON_REV_MOVEMENT: {
    HOME:"/non-rev-movement",
    CREATE:"/create-non-rev-movement",
    EDIT:"/non-rev-movement/:id/edit",
   },

   COUNTER_FLEET_LIST_REPORT: {
    HOME:"/fleet-list-report",
    CREATE:"/create-fleet-list-report",
    EDIT:"/fleet-list-report/:id/edit",
   },
   COUNTER_FLEET_DUE_BACK_REPORT: {
    HOME:"/fleet-due-back-report",
    CREATE:"/create-fleet-due-back-report",
    EDIT:"/fleet-due-back-report/:id/edit",
   },
   COUNTER_CIRCLE_SHEET_REPORT: {
    HOME:"/circle-sheet-report",
    CREATE:"/create-circle-sheet-report",
    EDIT:"/circle-sheet-report/:id/edit",
   },
   COUNTER_NET_AVAILABILITY_REPORT: {
    HOME:"/net-availability-report",
    CREATE:"/create-net-availability-report",
    EDIT:"/net-availability-report/:id/edit",
   },
   COUNTER_FLEET_PERFORMANCE_REPORT: {
    HOME:"/fleet-performance-report",
    CREATE:"/create-fleet-performance-report",
    EDIT:"/fleet-performance-report/:id/edit",
   },
   COUNTER_RESERVASTIONS_REPORT: {
    HOME:"/reservations-report",
    CREATE:"/create-reservations-report",
    EDIT:"/reservations-report/:id/edit",
   },
   COUNTER_REVENUE_REPORT: {
    HOME:"/revenue-report",
    CREATE:"/create-revenue-report",
    EDIT:"/revenue-report/:id/edit",
   },
   COUNTER_PAYMENTS_REPORT: {
    HOME:"/payments-report",
    CREATE:"/create-payments-report",
    EDIT:"/payments-report/:id/edit",
   },
   COUNTER_ACCOUNTS_RECEIVABLE_REPORT: {
    HOME:"/accounts-receivable-report",
    CREATE:"/create-accounts-receivable-report",
    EDIT:"/accounts-receivable-report/:id/edit",
   },
   COUNTER_RENTAL_OVERDUE_REPORT: {
    HOME:"/rental-overdue-report",
    CREATE:"/create-rental-overdue-report",
    EDIT:"/rental-overdue-report/:id/edit",
   },
   
   SYSTEM_USERS:{
    HOME:"/users",
    CREATE:"/create-users",
    // VIEW:"/view-users/:id/view",
    EDIT:"/edit-users/:id/edit",
   },
}