import React from "react";
import Promotion from "../../../component/Website/Promotion";

const PromotionScreen = () => {
  return (
    <div>
      <Promotion />
    </div>
  );
};

export default PromotionScreen;
