import { baseURL } from "../utils/constant";

export const baseUrl =baseURL;
export const loadingShow = (hidden) => {
    let loading = document.getElementById('loading');
    loading.style.display = hidden;
  }
export const postApiLoginAsyn = async (type, UserData) => {
    loadingShow('block');
    let result = await fetch(baseURL + type, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(UserData),
    })
      .then((response) => {
        loadingShow('none');
        if (response.status === 401) {
        }
        //let resobjec = response.json();
        //  resobjec = resobjec["token"] = response.headers.get('authorization')
        return response;
      })
      .then((data) => {
        // data['authorization'] = data.headers.get('authorization');
        // data['APIResult'] = 1;
        return data.json();
      })
      .catch((error) => {
        loadingShow('none');
        error['APIResult'] = 0;
        if (error.TypeError) {
          error['Message'] = 'Internal server error (' + error.TypeError + ')';
        }
        return error;
      });
    return result;
  };

  
export function getApi(type, token) {
  //loadingShow('block');
  return fetch(baseURL + type, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      authorization: token,
    },
    method: 'GET',
  })
    .then((response) => {
      if (response.status === 401) {
        loadingShow('none');
        window.location.replace('/');
      }
      return response;
    })
    .then((responseJson) => {
      loadingShow('none');
      return responseJson.json();
    })
    .catch((error) => {
      throw error;
    });
}


export const getApiParam = async (type, param) => {
  loadingShow('block');
  let result = await fetch(baseURL + type + param, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    method: 'GET'
    })
    .then((response) => {
      loadingShow('none');
      if (response.status === 401) {
      }
      return response;
    })
    .then((data) => {
      return data.json();
    })
    .catch((error) => {
      loadingShow('none');
      error['APIResult'] = 0;
      if (error.TypeError) {
        error['Message'] = 'Internal server error (' + error.TypeError + ')';
      }
      return error;
    });
  return result;
};


export const getApiAsyn = async (type, token, shouldPrependBaseUrl = true) => {
  const url = shouldPrependBaseUrl ? baseURL + type : type;
  let result = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: "Bearer "+ token,
    },
    method: 'GET',
  })
    .then((response) => {
      loadingShow('none');
      if (response.status === 401) {
        localStorage.clear();
        window.location.replace('/');
      }
      if (response.status === 400) {
        throw new Error(response)
      }
      return response.json();
    }, (error) => {
      console.log("ERRoR XX", error )
    })
    .then((data) => {
      data['APIResult'] = 1;
      return data;
    })
    .catch((error) => {
      console.log(error, "XXX")
      loadingShow('none');
      error['APIResult'] = 0;

      error['Message'] = 'Internal server error (' + error.TypeError + ')';
      error = {
        Message: 'Internal server error (' + error.TypeError + ')',
        type: 'error',
      };
      return error;
    });
  return result;
};


export const getApiAsynarrayBuffer = async (type, token, shouldPrependBaseUrl = true) => {
  const url = shouldPrependBaseUrl ? baseURL + type : type;
  let result = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: "Bearer "+ token,
    },
    method: 'GET',
  })
    .then((response) => {
      loadingShow('none');
      if (response.status === 401) {
        localStorage.clear();
        window.location.replace('/');
      }
      if (response.status === 400) {
        throw new Error(response)
      }
      return response.arrayBuffer();
    }, (error) => {
      console.log("ERRoR XX", error )
    })
    .then((data) => {
      data['APIResult'] = 1;
      return data;
    })
    .catch((error) => {
      console.log(error, "XXX")
      loadingShow('none');
      error['APIResult'] = 0;

      error['Message'] = 'Internal server error (' + error.TypeError + ')';
      error = {
        Message: 'Internal server error (' + error.TypeError + ')',
        type: 'error',
      };
      return error;
    });
  return result;
};

export const postApiAsynArrayBuffer = async (type, payload, token, shouldPrependBaseUrl = true) => {
  const url = shouldPrependBaseUrl ? baseURL + type : type;
  
  let result = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: "Bearer " + token,
    },
    method: 'POST', 
    body: JSON.stringify(payload),
  })
    .then((response) => {
      if (response.status === 401) {
        localStorage.clear();
        window.location.replace('/');
      }
      if (response.status === 400) {
        throw new Error(response);
      }
      return response.arrayBuffer();
    })
    .catch((error) => {
      console.error("Error:", error);
      return { Message: 'Internal server error', type: 'error' };
    });

  return result;
};



export const validationForSpecialchar = (e) => {
  var result = /[^a-z 0-9 A-Z\\,\\.\\;]/g;
  if (!result.test(e)) {
    return false;
  }
  return true;
};
export const validateEmail = (email) => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};
export const deleteApiAsyn = async (type, UserData, token) => {
  let result = await fetch(baseURL + type, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: "Bearer "+ token,
    },
    method: 'DELETE',
    body: JSON.stringify(UserData),
  })
    .then((response) => {
      if (response.status === 401) {
      }
      return response.json();
    })
    .then((data) => {
      data['APIResult'] = 1;
      return data;
    })
    .catch((error) => {
      error['APIResult'] = 0;
      if (error.TypeError) {
        error['Message'] = 'Internal server error (' + error.TypeError + ')';
      }
      return error;
    });
  return result;
};




export const deleteApi = async (type, token) => {
  const url = baseURL + type;
  let result = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: "Bearer "+ token,
    },
    method: 'GET',
  })
    .then((response) => {
      loadingShow('none');
      if (response.status === 401) {
        localStorage.clear();
        window.location.replace('/');
      }
      if (response.status === 400) {
        throw new Error(response)
      }
      return response.json();
    }, (error) => {
      console.log("ERRoR XX", error )
    })
    .then((data) => {
      data['APIResult'] = 1;
      return data;
    })
    .catch((error) => {
      console.log(error, "XXX")
      loadingShow('none');
      error['APIResult'] = 0;

      error['Message'] = 'Internal server error (' + error.TypeError + ')';
      error = {
        Message: 'Internal server error (' + error.TypeError + ')',
        type: 'error',
      };
      return error;
    });
  return result;
};
export function deleteApi2(type, token) {
  return fetch(baseUrl + type, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: "Bearer "+ token,
    },
    method: 'DELETE',
  })
    .then((response) => {
      if (response.status === 401) {
        window.location.replace('/');
      }

      return response;
    })
    .then((responseJson) => {
      if (responseJson.status === 200) {
        return responseJson;
      } else {
        return responseJson.json();
      }
    })
    .catch((error) => {
      throw error;
    });
}