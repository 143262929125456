import React from "react";
import SendNotifications from "../../../component/Communication/SendNotifications";

const SendNotificationsScreen = () => {
  return (
    <div>
      <SendNotifications />
    </div>
  );
};

export default SendNotificationsScreen;
