import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import SideNav from "./SideNav";
import Router from "../../router";
import { useStyles } from "./style";

const MainLayout = ({ switchHeader }) => {
  const classes = useStyles();

  return (
    <div className={classes.layout}>
      <div className={classes.header}>
        <Header />
      </div>
      <div className={classes.main}>
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <div className={classes.contentArea}>
          <Router switchHeader={switchHeader} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MainLayout;
