import React from "react";
import MaintenanceRecords from "../../../component/Maintenance/MaintenanceRecords";

const MaintenanceRecordsScreen = () => {
  return (
    <div>
      <MaintenanceRecords />
    </div>
  );
};

export default MaintenanceRecordsScreen;
