import React from "react";
import RentalOverdueReport from "../../../component/CounterReports/RentalOverdueReport";

const RentalOverdueReportScreen = () => {
  return (
    <div>
      <RentalOverdueReport />
    </div>
  );
};

export default RentalOverdueReportScreen;
