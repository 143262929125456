import React from "react";
import SendBulkSMS from "../../../component/Communication/SendBulkSMS";

const SendBulkSMSScreen = () => {
  return (
    <div>
      <SendBulkSMS />
    </div>
  );
};

export default SendBulkSMSScreen;
