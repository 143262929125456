import axios from 'axios';
import { getApiAsyn, baseUrl, getApiAsynarrayBuffer, deleteApi, postApiAsynArrayBuffer } from "../../../PostAPI";
import { handleAPIError, APIError } from "../../../common/errorHandler";
import { API_URL } from "../../../../utils/constant";
import { extractData, formatDatesHyphan } from "../../../../utils";
import { decorateWithPaginationParamsPost, decorateWithSortParamsPost, getDecoratedUrl } from "../../../common/urlService";
import { getPayloadInfo } from '../ReservationsServices';
const { post, put, get } = axios;

const getCommonRequestBody = (payload) => {
  const { isSearch } = payload;
  let sortdata = decorateWithSortParamsPost(payload);
  if (sortdata === "") {
    sortdata = "id,desc";
  }

  let startDate = formatDatesHyphan(getPayloadInfo(payload?.additinalFilter, "startDate"));
  let endDate = formatDatesHyphan(getPayloadInfo(payload?.additinalFilter, "endDate"));
  let locationId = getPayloadInfo(payload?.additinalFilter, "location");
  let networkId = getPayloadInfo(payload?.additinalFilter, "network");
  let pageno = isSearch ? {} : decorateWithPaginationParamsPost(payload);
  let status  = getPayloadInfo(payload?.additinalFilter, "status");
  let type  = getPayloadInfo(payload?.additinalFilter, "type");

  return {
    startDate: startDate ? startDate : "00:00:00",
    endDate: endDate ? endDate : "00:00:00",
    locationId: (locationId ?? "") === "" ? [0] : locationId,
    networkId: (networkId ?? "") === "" ? [0] : networkId,
    status: (status ?? "") === "" ? 0 : status,
    type: type ?? "",
    paged: true,
    pageNumber: isSearch ? payload.pageNumber : pageno?.pageNumber,
    pageSize: isSearch ? payload.pageSize : pageno?.pageSize,
    sort: sortdata,
    userId: localStorage.getItem("userId"),
  };
};

export const fetchReservationsDetailReport = async (payload) => {
  let requestBody = getCommonRequestBody(payload);
  try {
    let result = await dataUploadApi(`${API_URL.RESERVATIONS_DETAILREPORT.GET}`, requestBody);
    if (result?.error) {
      throw new APIError(result);
    }
    return result; //extractData(result, !payload?.filters);
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const fetchReservationsDetailReportDropdown = async (payload) => {
  const url = API_URL.RESERVATIONS_DETAILREPORT.DROPDOWN;
  try {
    let result = await getApiAsyn(url, localStorage.getItem("token"));

    if (result?.error) {
      throw new APIError(result);
    }

    return result; //extractData(result, !payload?.filters);
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};



export const multipartDataUploadImage = (url, payload) => {
 
  const formPayload = new FormData();
  formPayload.append("file", payload?.file[0]);
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      authorization: localStorage.getItem("token"),
    },
  };
  return post(baseUrl + url, formPayload, config);
};



export const upladImageData = async (payload) => {
  const url = API_URL.UPLOAD_IMAGE.replace(
    ":id",
    payload.id
  );
  try {
    let result = await multipartDataUploadImage(
      url,
      payload
    );

    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};
const dataUploadApi = (url, data, isEdit = false) => {
  // const formPayload = new FormData();
  // // data part of multipart data
  // const json = JSON.stringify(data);
  // const blobData = new Blob([json], {
  //   type: 'application/json',
  // });
  // formPayload.append('data', blobData);

  // // file part of multipart data
  // files?.forEach((file) => {
  //   formPayload.append('file', file);
  // });

  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: localStorage.getItem("token"),
    },
  };

  if (isEdit) {
    return post(baseUrl + url, data, config);
  } else {
    return post(baseUrl + url, data, config);
  }
};
export const createReservationsDetailReport = async (payload) => {
  try {
    let result = await dataUploadApi(
      `${API_URL.RESERVATIONS_DETAILREPORT.CREATE}`,
      payload
    );

    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const editReservationsDetailReport = async (payload) => {
  const url = API_URL.RESERVATIONS_DETAILREPORT.EDIT.replace(":id", payload?.id);

  try {
    let result = await dataUploadApi(url, payload.data, true);

    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};





const dataExportApi = (url, data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: localStorage.getItem("token"),
    },
  };
    return get(baseUrl + url, data, config);
};

export const exportReservationsDetailReportRecord = async (payload) => {
  const url = API_URL.RESERVATIONS_DETAILREPORT.EXPORT;
  let requestBody = getCommonRequestBody(payload);
  try {
    let result = await postApiAsynArrayBuffer(url, requestBody, localStorage.getItem("token"));
    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};




export const fetchReservationsDetailReportById = async (payload) => {
  const url = API_URL.RESERVATIONS_DETAILREPORT.GET_BY_ID.replace(
    ":id",
    payload?.id
  );
  try {
    let result = await getApiAsyn(url, localStorage.getItem("token"));

    if (result?.error) {
      throw new APIError(result);
    }

    result = extractData(result, !payload?.filters);

    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};
export const deleteReservationsDetailReport = async (payload) => {
  try {
     const url = API_URL.RESERVATIONS_DETAILREPORT.DELETE.replace(
      ":id",
      payload?.id
    );

      let result = await deleteApi(url, localStorage.getItem("token"));
    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};  