import React from "react";
import BranchSummary from "../../../component/Reports/BranchSummary";

const BranchSummaryScreen = () => {
  return (
    <div>
      <BranchSummary />
    </div>
  );
};

export default BranchSummaryScreen;
