import React from 'react'
import {  Routes, Route } from "react-router-dom";
import Login from '../container/Login';
import Dashboard from '../container/DashboardScreen';
import LocationScreen from '../container/LocationScreen';
import NotFound from '../NotFound';
import CreateLocationContainer from '../container/LocationScreen/CreateLocation';
import { APP_URLS } from '../utils/constant';
import VehicleCategoryScreen from '../container/Vehicles/VehicleCategoryScreen';
import CreateVehicleCategory from '../container/Vehicles/VehicleCategoryScreen/CreateVehicleCategory';
import VehicleMakeScreen from '../container/Vehicles/VehicleMakeScreen';
import CreateVehicleMake from '../container/Vehicles/VehicleMakeScreen/CreateVehicleMake';

import VehicleModelScreen from '../container/Vehicles/VehicleModelScreen';
import CreateVehicleModel from '../container/Vehicles/VehicleModelScreen/CreateVehicleModel';
import VehicleTrimScreen from '../container/Vehicles/VehicleTrimScreen';
import CreateVehicleTrim from '../container/Vehicles/VehicleTrimScreen/CreateVehicleTrim';
import VehicleTransmissionTypeScreen from '../container/Vehicles/VehicleTransmissionTypeScreen';
import CreateVehicleTransmissionType from '../container/Vehicles/VehicleTransmissionTypeScreen/CreateVehicleTransmissionType';
import VehicleAcTypeScreen from '../container/Vehicles/VehicleAcTypeScreen';
import CreateVehicleAcType from '../container/Vehicles/VehicleAcTypeScreen/CreateVehicleAcType';
import VehicleStereoTypeScreen from '../container/Vehicles/VehicleStereoTypeScreen';
import CreateVehicleStereoType from '../container/Vehicles/VehicleStereoTypeScreen/CreateVehicleStereoType';
import ResetPassword from '../container/Login/Reset';
import CreatePasswordContainer from '../container/ProfileScreen/CreateChangePassword';
import VehicleVehicleStatusScreen from '../container/Vehicles/VehicleVehicleStatusScreen';
import CreateVehicleVehicleStatus from '../container/Vehicles/VehicleVehicleStatusScreen/CreateVehicleVehicleStatus';
import VehicleVehicleOperationsScreen from '../container/Vehicles/VehicleVehicleOperationsScreen';
import CreateVehicleVehicleOperations from '../container/Vehicles/VehicleVehicleOperationsScreen/CreateVehicleVehicleOperations';
import VehicleDealershipsScreen from '../container/Vehicles/VehicleDealershipsScreen';
import CreateVehicleDealerships from '../container/Vehicles/VehicleDealershipsScreen/CreateVehicleDealerships';
import VehicleBodyShopScreen from '../container/Vehicles/VehicleBodyShopScreen';
import CreateVehicleBodyShop from '../container/Vehicles/VehicleBodyShopScreen/CreateVehicleBodyShop';
import VehicleDealershipVehicleDealScreen from '../container/Vehicles/VehicleDealershipVehicleDealScreen';
import CreateVehicleDealershipVehicleDeal from '../container/Vehicles/VehicleDealershipVehicleDealScreen/CreateVehicleDealershipVehicleDeal';
import RateAdminRateCodeScreen from '../container/RateAdminScreen/RateAdminRateCodeScreen';
import CreateRateAdminRateCode from '../container/RateAdminScreen/RateAdminRateCodeScreen/CreateRateAdminRateCode';
import RateAdminRatePlanScreen from '../container/RateAdminScreen/RateAdminRatePlanScreen';
import CreateRateAdminRatePlan from '../container/RateAdminScreen/RateAdminRatePlanScreen/CreateRateAdminRatePlan';
import RateAdminRateTypesScreen from '../container/RateAdminScreen/RateAdminRateTypesScreen';
import CreateRateAdminRateTypes from '../container/RateAdminScreen/RateAdminRateTypesScreen/CreateRateAdminRateTypes';
import RateAdminLorAdjustmentsScreen from '../container/RateAdminScreen/RateAdminLorAdjustmentsScreen';
import CreateRateAdminLorAdjustments from '../container/RateAdminScreen/RateAdminLorAdjustmentsScreen/CreateRateAdminLorAdjustments';
import CreateRateAdminRates from '../container/RateAdminScreen/RateAdminRatesScreen/CreateRateAdminRates';
import RateAdminRatesScreen from '../container/RateAdminScreen/RateAdminRatesScreen';
import RateAdminExtraProductCategoryScreen from '../container/RateAdminScreen/RateAdminExtraProductCategoryScreen';
import CreateRateAdminExtraProductCategory from '../container/RateAdminScreen/RateAdminExtraProductCategoryScreen/CreateRateAdminExtraProductCategory';
import RateAdminExtraProductChargeScreen from '../container/RateAdminScreen/RateAdminExtraProductChargeScreen';
import CreateRateAdminExtraProductCharge from '../container/RateAdminScreen/RateAdminExtraProductChargeScreen/CreateRateAdminExtraProductCharge';
import RateAdminNetworksScreen from '../container/RateAdminScreen/RateAdminNetworksScreen';
import CreateRateAdminNetworks from '../container/RateAdminScreen/RateAdminNetworksScreen/CreateRateAdminNetworks';
import RateAdminBlackoutsScreen from '../container/RateAdminScreen/RateAdminBlackoutsScreen';
import CreateRateAdminBlackouts from '../container/RateAdminScreen/RateAdminBlackoutsScreen/CreateRateAdminBlackouts';
import RateAdminRateMirrorsScreen from '../container/RateAdminScreen/RateAdminRateMirrorsScreen';
import CreateRateAdminRateMirrors from '../container/RateAdminScreen/RateAdminRateMirrorsScreen/CreateRateAdminRateMirrors';
import HolidaysScreen from '../container/HolidaysScreen';
import CreateHolidays from '../container/HolidaysScreen/CreateHolidays';
import SettingsSettingWeekendsScreen from '../container/Settings/SettingsSettingWeekendsScreen';
import CreateSettingsSettingWeekends from '../container/Settings/SettingsSettingWeekendsScreen/CreateSettingsSettingWeekends';
import SettingsPaynowDiscountsScreen from '../container/Settings/SettingsPaynowDiscountsScreen';
import CreateSettingsPaynowDiscounts from '../container/Settings/SettingsPaynowDiscountsScreen/CreateSettingsPaynowDiscounts';
import SettingsPaynowOptionsScreen from '../container/Settings/SettingsPaynowOptionsScreen';
import CreateSettingsPaynowOptions from '../container/Settings/SettingsPaynowOptionsScreen/CreateSettingsPaynowOptions';
import VehicleCategoryRouter from './vehicleModule';
import RentersRentersInfoScreen from '../container/Renters/RentersRentersInfoScreen';
import CreateRentersRentersInfo from '../container/Renters/RentersRentersInfoScreen/CreateRentersRentersInfo';
import RentersRevenueAgreementsScreen from '../container/Renters/RentersRevenueAgreementsScreen';
import CreateRentersRevenueAgreements from '../container/Renters/RentersRevenueAgreementsScreen/CreateRentersRevenueAgreements';
import RentersNonRevenueAgreementsScreen from '../container/Renters/RentersNonRevenueAgreementsScreen';
import CreateRentersNonRevenueAgreements from '../container/Renters/RentersNonRevenueAgreementsScreen/CreateRentersNonRevenueAgreements';
import RentersVehicleInquiryScreen from '../container/Renters/RentersVehicleInquiryScreen';
import CreateRentersVehicleInquiry from '../container/Renters/RentersVehicleInquiryScreen/CreateRentersVehicleInquiry';
import RentersRegisteredRentersScreen from '../container/Renters/RentersRegisteredRentersScreen';
import RentersRegisteredRentersViewScreen from '../container/Renters/RentersRegisteredRentersScreen/ViewRentersRegisteredRenters';
import CreateRentersRegisteredRenters from '../container/Renters/RentersRegisteredRentersScreen/CreateRentersRegisteredRenters';
import RentersLogInteractionsScreen from '../container/Renters/RentersLogInteractionsScreen';
import CreateRentersLogInteractions from '../container/Renters/RentersLogInteractionsScreen/CreateRentersLogInteractions';
import ReservationsReservationsScreen from '../container/Reservations/ReservationsReservationsScreen';
import CreateReservationsReservations from '../container/Reservations/ReservationsReservationsScreen/CreateReservationsReservations';
import ReservationsDetailReportScreen from '../container/Reservations/ReservationsDetailReportScreen';
import CreateReservationsDetailReport from '../container/Reservations/ReservationsDetailReportScreen/CreateReservationsDetailReport';
import ReservationsDailyManifestReportScreen from '../container/Reservations/ReservationsDailyManifestReportScreen';
import CreateReservationsDailyManifestReport from '../container/Reservations/ReservationsDailyManifestReportScreen/CreateReservationsDailyManifestReport';
import ReservationsBookingsbyLocationReportScreen from '../container/Reservations/ReservationsBookingsbyLocationReportScreen';
import CreateReservationsBookingsbyLocationReport from '../container/Reservations/ReservationsBookingsbyLocationReportScreen/CreateReservationsBookingsbyLocationReport';
import ReservationsPrepaidBookingsReportScreen from '../container/Reservations/ReservationsPrepaidBookingsReportScreen';
import CreateReservationsPrepaidBookingsReport from '../container/Reservations/ReservationsPrepaidBookingsReportScreen/CreateReservationsPrepaidBookingsReport';
import ReservationsCRXRezpowerReservationsScreen from '../container/Reservations/ReservationsCRXRezpowerReservationsScreen';
import CreateReservationsCRXRezpowerReservations from '../container/Reservations/ReservationsCRXRezpowerReservationsScreen/CreateReservationsCRXRezpowerReservations';
import UserScreen from '../container/System/UsersScreen';
import ReservationsQuotesScreen from '../container/Reservations/ReservationsQuotesScreen';
import ReservationsBidsScreen from '../container/Reservations/ReservationsBidsScreen';
import ReservationsTransactionsScreen from '../container/Reservations/ReservationsTransactionsScreen';
import CalendarScreen from '../container/TaskAssignment/CalendarScreen';
import RecurringToDoListScreen from '../container/TaskAssignment/RecurringToDoListScreen';
import TaskManagementSystemScreen from '../container/TaskAssignment/TaskManagementSystemScreen';
import CorporateMeetingsScreen from '../container/Admin/CorporateMeetingsScreen';
import LeaveApplicationsScreen from '../container/Admin/LeaveApplicationsScreen';
import ArScreen from '../container/Financial/ArScreen';
import InvoicingScreen from '../container/Financial/InvoicingScreen';
import MiscUploadsScreen from '../container/Financial/MiscUploadsScreen';
import RentalChecklistScreen from '../container/Financial/RentalChecklistScreen';
import TimeAndBillingScreen from '../container/Financial/TimeAndBillingScreen';
import VendorsScreen from '../container/Financial/VendorsScreen';
import WholesaleBillOfSaleScreen from '../container/Financial/WholesaleBillOfSaleScreen';
import SendBulkEmailScreen from '../container/Communication/SendBulkEmailScreen';
import SendBulkSMSScreen from '../container/Communication/SendBulkSMSScreen';
import SendNotificationsScreen from '../container/Communication/SendNotificationsScreen';
import StaffScreen from '../container/Communication/StaffScreen';
import CareersScreen from '../container/Operations/CareersScreen';
import FormsScreen from '../container/Operations/FormsScreen';
import NewslettersScreen from '../container/Operations/NewslettersScreen';
import StaffManagementScreen from '../container/Operations/StaffManagementScreen';
import BlogsScreen from '../container/Website/BlogsScreen';
import PromotionScreen from '../container/Website/PromotionScreen';
import TrafficLogsScreen from '../container/Website/TrafficLogsScreen';

import BranchSummaryScreen from '../container/Reports/BranchSummaryScreen';
import DailyBookingsStatisticsScreen from '../container/Reports/DailyBookingsStatisticsScreen';
import DailyDropoffManifestScreen from '../container/Reports/DailyDropoffManifestScreen';
import NoShowManifestScreen from '../container/Reports/NoShowManifestScreen';
import PayOnArrivalBookingsScreen from '../container/Reports/PayOnArrivalBookingsScreen';
import MaintenanceCategorysScreen from '../container/Maintenance/MaintenanceCategorysScreen';
import MaintenanceRecordsScreen from '../container/Maintenance/MaintenanceRecordsScreen';
import ClaimsCostCategoryScreen from '../container/ClaimsManager/ClaimsCostCategoryScreen';
import ClaimsScreen from '../container/ClaimsManager/ClaimsScreen';
import BodyRepairShopsScreen from '../container/CounterManager/BodyRepairShopsScreen';
import BroadcastMessagesScreen from '../container/CounterManager/BroadcastMessagesScreen';
import NonRevMovementScreen from '../container/CounterManager/NonRevMovementScreen';
import AccountsReceivableReportScreen from '../container/CounterReports/AccountsReceivableReportScreen';
import CircleSheetReportScreen from '../container/CounterReports/CircleSheetReportScreen';
import FleetDueBackReportScreen from '../container/CounterReports/FleetDueBackReportScreen';
import FleetListReportScreen from '../container/CounterReports/FleetListReportScreen';
import FleetPerformanceReportScreen from '../container/CounterReports/FleetPerformanceReportScreen';
import NetAvailabilityReportScreen from '../container/CounterReports/NetAvailabilityReportScreen';
import PaymentsReportScreen from '../container/CounterReports/PaymentsReportScreen';
import RentalOverdueReportScreen from '../container/CounterReports/RentalOverdueReportScreen';
import ReservationReportScreen from '../container/CounterReports/ReservationReportScreen';
import RevenueReportScreen from '../container/CounterReports/RevenueReportScreen';
import CreateUsersContainer from '../container/System/UsersScreen/CreateUsers';

export default function Router(props) {
  const Update = () =>{
    props.switchHeader()
  }
  const renderVehicleCategoryRoutes = (access) => (
    <Route
      path={APP_URLS.VEHICLE_CATEGORY.HOME}
      element={<VehicleCategoryRouter access={access?.invoiceMenu} />}
    />
  );
  return (
    <Routes>
         <Route exact={true} path="/" element={<Login callBack={Update}/>} />
         <Route path="/reset" element={<ResetPassword />} />
         <Route path="/dashboard" element={<Dashboard />} />
         <Route path="/location" element={<LocationScreen  />} />
         <Route path={APP_URLS.CHANGEPASSWORD} element={<CreatePasswordContainer  />} /> 

         <Route path={APP_URLS.LOCATION.CREATE} element={<CreateLocationContainer  />} /> 
         <Route path={APP_URLS.LOCATION.EDIT} element={<CreateLocationContainer isEdit={true} />} /> 
         
         
         <Route path={APP_URLS.VEHICLE_CATEGORY.HOME} element={<VehicleCategoryScreen  />} />
         <Route path={APP_URLS.VEHICLE_CATEGORY.CREATE} element={<CreateVehicleCategory  />} />
         <Route path={APP_URLS.VEHICLE_CATEGORY.EDIT} element={<CreateVehicleCategory isEdit={true} />} />

         <Route path={APP_URLS.VEHICLE_MAKE.HOME} element={<VehicleMakeScreen  />} />
         <Route path={APP_URLS.VEHICLE_MAKE.CREATE} element={<CreateVehicleMake  />} />
         <Route path={APP_URLS.VEHICLE_MAKE.EDIT} element={<CreateVehicleMake isEdit={true} />} />

         <Route path={APP_URLS.VEHICLE_MODEL.HOME} element={<VehicleModelScreen  />} />
         <Route path={APP_URLS.VEHICLE_MODEL.CREATE} element={<CreateVehicleModel  />} />
         <Route path={APP_URLS.VEHICLE_MODEL.EDIT} element={<CreateVehicleModel isEdit={true} />} />

         <Route path={APP_URLS.VEHICLE_TRIM.HOME} element={<VehicleTrimScreen  />} />
         <Route path={APP_URLS.VEHICLE_TRIM.CREATE} element={<CreateVehicleTrim  />} />
         <Route path={APP_URLS.VEHICLE_TRIM.EDIT} element={<CreateVehicleTrim isEdit={true} />} />

         <Route path={APP_URLS.VEHICLE_TRANSMISSIONTYPE.HOME} element={<VehicleTransmissionTypeScreen  />} />
         <Route path={APP_URLS.VEHICLE_TRANSMISSIONTYPE.CREATE} element={<CreateVehicleTransmissionType  />} />
         <Route path={APP_URLS.VEHICLE_TRANSMISSIONTYPE.EDIT} element={<CreateVehicleTransmissionType isEdit={true} />} />

         <Route path={APP_URLS.VEHICLE_ACTYPE.HOME} element={<VehicleAcTypeScreen  />} />
         <Route path={APP_URLS.VEHICLE_ACTYPE.CREATE} element={<CreateVehicleAcType  />} />
         <Route path={APP_URLS.VEHICLE_ACTYPE.EDIT} element={<CreateVehicleAcType isEdit={true} />} />

         <Route path={APP_URLS.VEHICLE_STEREOTYPE.HOME} element={<VehicleStereoTypeScreen  />} />
         <Route path={APP_URLS.VEHICLE_STEREOTYPE.CREATE} element={<CreateVehicleStereoType  />} />
         <Route path={APP_URLS.VEHICLE_STEREOTYPE.EDIT} element={<CreateVehicleStereoType isEdit={true} />} />


         <Route path={APP_URLS.VEHICLE_VEHICLESTATUS.HOME} element={<VehicleVehicleStatusScreen  />} />
         <Route path={APP_URLS.VEHICLE_VEHICLESTATUS.CREATE} element={<CreateVehicleVehicleStatus   />} />
         <Route path={APP_URLS.VEHICLE_VEHICLESTATUS.EDIT} element={<CreateVehicleVehicleStatus isEdit={true} />} />

         <Route path={APP_URLS.VEHICLE_VEHICLEOPERATIONS.HOME} element={<VehicleVehicleOperationsScreen  />} />
         <Route path={APP_URLS.VEHICLE_VEHICLEOPERATIONS.CREATE} element={<CreateVehicleVehicleOperations   />} />
         <Route path={APP_URLS.VEHICLE_VEHICLEOPERATIONS.EDIT} element={<CreateVehicleVehicleOperations isEdit={true} />} />

         <Route path={APP_URLS.VEHICLE_DEALERSHIPS.HOME} element={<VehicleDealershipsScreen  />} />
         <Route path={APP_URLS.VEHICLE_DEALERSHIPS.CREATE} element={<CreateVehicleDealerships   />} />
         <Route path={APP_URLS.VEHICLE_DEALERSHIPS.EDIT} element={<CreateVehicleDealerships isEdit={true} />} />
       
       
         <Route path={APP_URLS.VEHICLE_BODYSHOP.HOME} element={<VehicleBodyShopScreen  />} />
         <Route path={APP_URLS.VEHICLE_BODYSHOP.CREATE} element={<CreateVehicleBodyShop />} />
         <Route path={APP_URLS.VEHICLE_BODYSHOP.EDIT} element={<CreateVehicleBodyShop isEdit={true} />} />
       
         <Route path={APP_URLS.VEHICLE_DEALERSHIPVEHICLEDEAL.HOME} element={<VehicleDealershipVehicleDealScreen />} />
         <Route path={APP_URLS.VEHICLE_DEALERSHIPVEHICLEDEAL.CREATE} element={<CreateVehicleDealershipVehicleDeal />} />
         <Route path={APP_URLS.VEHICLE_DEALERSHIPVEHICLEDEAL.EDIT} element={<CreateVehicleDealershipVehicleDeal isEdit={true} />} />
       
         <Route path={APP_URLS.RATEADMIN_RATE_CODE.HOME} element={<RateAdminRateCodeScreen />} />
         <Route path={APP_URLS.RATEADMIN_RATE_CODE.CREATE} element={<CreateRateAdminRateCode />} />
         <Route path={APP_URLS.RATEADMIN_RATE_CODE.EDIT} element={<CreateRateAdminRateCode isEdit={true} />} />

         <Route path={APP_URLS.RATEADMIN_RATE_PLAN.HOME} element={<RateAdminRatePlanScreen />} />
         <Route path={APP_URLS.RATEADMIN_RATE_PLAN.CREATE} element={<CreateRateAdminRatePlan />} />
         <Route path={APP_URLS.RATEADMIN_RATE_PLAN.EDIT} element={<CreateRateAdminRatePlan isEdit={true} />} />
       
         <Route path={APP_URLS.RATEADMIN_RATE_TYPES.HOME} element={<RateAdminRateTypesScreen />} />
         <Route path={APP_URLS.RATEADMIN_RATE_TYPES.CREATE} element={<CreateRateAdminRateTypes />} />
         <Route path={APP_URLS.RATEADMIN_RATE_TYPES.EDIT} element={<CreateRateAdminRateTypes isEdit={true} />} />
       
         <Route path={APP_URLS.RATEADMIN_LOR_ADJUSTMENTS.HOME} element={<RateAdminLorAdjustmentsScreen />} />
         <Route path={APP_URLS.RATEADMIN_LOR_ADJUSTMENTS.CREATE} element={<CreateRateAdminLorAdjustments />} />
         <Route path={APP_URLS.RATEADMIN_LOR_ADJUSTMENTS.EDIT} element={<CreateRateAdminLorAdjustments isEdit={true} />} />
       

         <Route path={APP_URLS.RATEADMIN_RATES.HOME} element={<RateAdminRatesScreen />} />
         <Route path={APP_URLS.RATEADMIN_RATES.CREATE} element={<CreateRateAdminRates />} />
         <Route path={APP_URLS.RATEADMIN_RATES.EDIT} element={<CreateRateAdminRates isEdit={true} />} />
         <Route path={APP_URLS.RATEADMIN_RATES.COPY} element={<CreateRateAdminRates isCopy={true} />} />

         <Route path={APP_URLS.RATEADMIN_EXTRAPRODUCTCATEGORY.HOME} element={<RateAdminExtraProductCategoryScreen />} />
         <Route path={APP_URLS.RATEADMIN_EXTRAPRODUCTCATEGORY.CREATE} element={<CreateRateAdminExtraProductCategory />} />
         <Route path={APP_URLS.RATEADMIN_EXTRAPRODUCTCATEGORY.EDIT} element={<CreateRateAdminExtraProductCategory isEdit={true} />} />
         
         <Route path={APP_URLS.RATEADMIN_EXTRAPRODUCTCHARGE.HOME} element={<RateAdminExtraProductChargeScreen />} />
         <Route path={APP_URLS.RATEADMIN_EXTRAPRODUCTCHARGE.CREATE} element={<CreateRateAdminExtraProductCharge />} />
         <Route path={APP_URLS.RATEADMIN_EXTRAPRODUCTCHARGE.EDIT} element={<CreateRateAdminExtraProductCharge isEdit={true} />} />
         <Route path={APP_URLS.RATEADMIN_EXTRAPRODUCTCHARGE.COPY} element={<CreateRateAdminExtraProductCharge isCopy={true} />} />
         
         <Route path={APP_URLS.RATEADMIN_NETWORK.HOME} element={<RateAdminNetworksScreen />} />
         <Route path={APP_URLS.RATEADMIN_NETWORK.CREATE} element={<CreateRateAdminNetworks />} />
         <Route path={APP_URLS.RATEADMIN_NETWORK.EDIT} element={<CreateRateAdminNetworks isEdit={true} />} />
       
         <Route path={APP_URLS.RATEADMIN_BLACKOUTS.HOME} element={<RateAdminBlackoutsScreen />} />
         <Route path={APP_URLS.RATEADMIN_BLACKOUTS.CREATE} element={<CreateRateAdminBlackouts />} />
         <Route path={APP_URLS.RATEADMIN_BLACKOUTS.EDIT} element={<CreateRateAdminBlackouts isEdit={true} />} />
       
         <Route path={APP_URLS.RATEADMIN_RATEMIRRORS.HOME} element={<RateAdminRateMirrorsScreen />} />
         <Route path={APP_URLS.RATEADMIN_RATEMIRRORS.CREATE} element={<CreateRateAdminRateMirrors />} />
         <Route path={APP_URLS.RATEADMIN_RATEMIRRORS.EDIT} element={<CreateRateAdminRateMirrors isEdit={true} />} />
        
         <Route path={APP_URLS.HOLIDAYS.HOME} element={<HolidaysScreen />} />
         <Route path={APP_URLS.HOLIDAYS.CREATE} element={<CreateHolidays />} />
         <Route path={APP_URLS.HOLIDAYS.EDIT} element={<CreateHolidays isEdit={true} />} />

         <Route path={APP_URLS.SETTINGS_SETTINGWEEKENDS.HOME} element={<SettingsSettingWeekendsScreen />} />
         <Route path={APP_URLS.SETTINGS_SETTINGWEEKENDS.CREATE} element={<CreateSettingsSettingWeekends />} />
         <Route path={APP_URLS.SETTINGS_SETTINGWEEKENDS.EDIT} element={<CreateSettingsSettingWeekends isEdit={true} />} />
        
         <Route path={APP_URLS.SETTINGS_PAYNOWDISCOUNTS.HOME} element={<SettingsPaynowDiscountsScreen />} />
         <Route path={APP_URLS.SETTINGS_PAYNOWDISCOUNTS.CREATE} element={<CreateSettingsPaynowDiscounts />} />
         <Route path={APP_URLS.SETTINGS_PAYNOWDISCOUNTS.EDIT} element={<CreateSettingsPaynowDiscounts isEdit={true} />} />
        
         <Route path={APP_URLS.SETTINGS_PAYNOWOPTIONS.HOME} element={<SettingsPaynowOptionsScreen />} />
         <Route path={APP_URLS.SETTINGS_PAYNOWOPTIONS.CREATE} element={<CreateSettingsPaynowOptions />} />
         <Route path={APP_URLS.SETTINGS_PAYNOWOPTIONS.EDIT} element={<CreateSettingsPaynowOptions isEdit={true} />} />
        
         <Route path={APP_URLS.RENTERS_RENTERSINFO.HOME} element={<RentersRentersInfoScreen />} />
         <Route path={APP_URLS.RENTERS_RENTERSINFO.CREATE} element={<CreateRentersRentersInfo />} />
         <Route path={APP_URLS.RENTERS_RENTERSINFO.EDIT} element={<CreateRentersRentersInfo isEdit={true} />} />
        
         <Route path={APP_URLS.RENTERS_REVENUEAGREEMENTS.HOME} element={<RentersRevenueAgreementsScreen />} />
         <Route path={APP_URLS.RENTERS_REVENUEAGREEMENTS.CREATE} element={<CreateRentersRevenueAgreements />} />
         <Route path={APP_URLS.RENTERS_REVENUEAGREEMENTS.EDIT} element={<CreateRentersRevenueAgreements isEdit={true} />} />
        
         <Route path={APP_URLS.RENTERS_NONREVENUEAGREEMENTS.HOME} element={<RentersNonRevenueAgreementsScreen />} />
         <Route path={APP_URLS.RENTERS_NONREVENUEAGREEMENTS.CREATE} element={<CreateRentersNonRevenueAgreements />} />
         <Route path={APP_URLS.RENTERS_NONREVENUEAGREEMENTS.EDIT} element={<CreateRentersNonRevenueAgreements isEdit={true} />} />
        
         <Route path={APP_URLS.RENTERS_VEHICLEINQUIRY.HOME} element={<RentersVehicleInquiryScreen />} />
         <Route path={APP_URLS.RENTERS_VEHICLEINQUIRY.CREATE} element={<CreateRentersVehicleInquiry />} />
         <Route path={APP_URLS.RENTERS_VEHICLEINQUIRY.EDIT} element={<CreateRentersVehicleInquiry isEdit={true} />} />
        
         <Route path={APP_URLS.RENTERS_REGISTEREDRENTERS.HOME} element={<RentersRegisteredRentersScreen />} />
         <Route path={APP_URLS.RENTERS_REGISTEREDRENTERS.CREATE} element={<CreateRentersRegisteredRenters />} />
         <Route path={APP_URLS.RENTERS_REGISTEREDRENTERS.VIEW} element={<RentersRegisteredRentersViewScreen />} />
         <Route path={APP_URLS.RENTERS_REGISTEREDRENTERS.EDIT} element={<CreateRentersRegisteredRenters isEdit={true} />} />
        
         <Route path={APP_URLS.RENTERS_LOGINTERACTIONS.HOME} element={<RentersLogInteractionsScreen />} />
         <Route path={APP_URLS.RENTERS_LOGINTERACTIONS.CREATE} element={<CreateRentersLogInteractions />} />
         <Route path={APP_URLS.RENTERS_LOGINTERACTIONS.EDIT} element={<CreateRentersLogInteractions isEdit={true} />} />
        

         <Route path={APP_URLS.RESERVATIONS_RESERVATIONS.HOME} element={<ReservationsReservationsScreen />} />
         <Route path={APP_URLS.RESERVATIONS_RESERVATIONS.CREATE} element={<CreateReservationsReservations />} />
         <Route path={APP_URLS.RESERVATIONS_RESERVATIONS.EDIT} element={<CreateReservationsReservations isEdit={true} />} />
        
         <Route path={APP_URLS.RESERVATIONS_DETAILREPORT.HOME} element={<ReservationsDetailReportScreen />} />
         <Route path={APP_URLS.RESERVATIONS_DETAILREPORT.CREATE} element={<CreateReservationsDetailReport />} />
         <Route path={APP_URLS.RESERVATIONS_DETAILREPORT.EDIT} element={<CreateReservationsDetailReport isEdit={true} />} />
        
         <Route path={APP_URLS.RESERVATIONS_DAILYMANIFESTREPORT.HOME} element={<ReservationsDailyManifestReportScreen />} />
         <Route path={APP_URLS.RESERVATIONS_DAILYMANIFESTREPORT.CREATE} element={<CreateReservationsDailyManifestReport />} />
         <Route path={APP_URLS.RESERVATIONS_DAILYMANIFESTREPORT.EDIT} element={<CreateReservationsDailyManifestReport isEdit={true} />} />
        
         <Route path={APP_URLS.RESERVATIONS_BOOKINGBYLOCATIONREPORT.HOME} element={<ReservationsBookingsbyLocationReportScreen />} />
         <Route path={APP_URLS.RESERVATIONS_BOOKINGBYLOCATIONREPORT.CREATE} element={<CreateReservationsBookingsbyLocationReport />} />
         <Route path={APP_URLS.RESERVATIONS_BOOKINGBYLOCATIONREPORT.EDIT} element={<CreateReservationsBookingsbyLocationReport isEdit={true} />} />
        
         <Route path={APP_URLS.RESERVATIONS_PREPAIDBOOKINGSREPORT.HOME} element={<ReservationsPrepaidBookingsReportScreen />} />
         <Route path={APP_URLS.RESERVATIONS_PREPAIDBOOKINGSREPORT.CREATE} element={<CreateReservationsPrepaidBookingsReport />} />
         <Route path={APP_URLS.RESERVATIONS_PREPAIDBOOKINGSREPORT.EDIT} element={<CreateReservationsPrepaidBookingsReport isEdit={true} />} />
        
         <Route path={APP_URLS.RESERVATIONS_CRXREZPOWERRESERVATIONS.HOME} element={<ReservationsCRXRezpowerReservationsScreen />} />
         <Route path={APP_URLS.RESERVATIONS_CRXREZPOWERRESERVATIONS.CREATE} element={<CreateReservationsCRXRezpowerReservations />} />
         <Route path={APP_URLS.RESERVATIONS_CRXREZPOWERRESERVATIONS.EDIT} element={<CreateReservationsCRXRezpowerReservations isEdit={true} />} />

         <Route path={APP_URLS.RESERVATIONS_QUOTES.HOME} element={<ReservationsQuotesScreen />} />

         <Route path={APP_URLS.RESERVATIONS_BIDS.HOME} element={<ReservationsBidsScreen />} />

         <Route path={APP_URLS.RESERVATIONS_TRANSACTIONS.HOME} element={<ReservationsTransactionsScreen />} />

         { renderVehicleCategoryRoutes() }

         <Route path={APP_URLS.WEBSITE_BLOGS.HOME} element={<BlogsScreen />} />
         <Route path={APP_URLS.WEBSITE_PROMOTION.HOME} element={<PromotionScreen />} />
         <Route path={APP_URLS.WEBSITE_TRAFFIC_LOGS.HOME} element={<TrafficLogsScreen />} />

         <Route path={APP_URLS.OPERATIONS_CAREERS.HOME} element={<CareersScreen />} />
         <Route path={APP_URLS.OPERATIONS_FORMS.HOME} element={<FormsScreen />} />
         <Route path={APP_URLS.OPERATIONS_NEWSLETTERS.HOME} element={<NewslettersScreen />} />
         <Route path={APP_URLS.OPERATIONS_STAFF_MANAGEMENT.HOME} element={<StaffManagementScreen />} />

         <Route path={APP_URLS.TASK_ASSIGNMENT_CALENDAR.HOME} element={<CalendarScreen />} />

         <Route path={APP_URLS.TASK_ASSIGNMENT_RECURRING_TO_DO_LIST.HOME} element={<RecurringToDoListScreen />} />

         <Route path={APP_URLS.TASK_ASSIGNMENT_TASK_MANAGEMENT_SYSTEM.HOME} element={<TaskManagementSystemScreen />} />

         <Route path={APP_URLS.ADMIN_CORPORATE_MEETINGS.HOME} element={<CorporateMeetingsScreen />} />

         <Route path={APP_URLS.ADMIN_LEAVE_APPLICATIONS.HOME} element={<LeaveApplicationsScreen />} />

         <Route path={APP_URLS.FINANCIAL_A_R.HOME} element={<ArScreen />} />
         <Route path={APP_URLS.FINANCIAL_INVOICING.HOME} element={<InvoicingScreen />} />
         <Route path={APP_URLS.FINANCIAL_MISC_UPLOADS.HOME} element={<MiscUploadsScreen />} />
         <Route path={APP_URLS.FINANCIAL_RENTAL_CHECKLIST.HOME} element={<RentalChecklistScreen />} />
         <Route path={APP_URLS.FINANCIAL_TIME_BILLINGS.HOME} element={<TimeAndBillingScreen />} />
         <Route path={APP_URLS.FINANCIAL_VENDORS.HOME} element={<VendorsScreen />} />
         <Route path={APP_URLS.FINANCIAL_WHOLESALE_BILL_OF_SALE.HOME} element={<WholesaleBillOfSaleScreen />} />

         <Route path={APP_URLS.COMMUNICATION_SEND_BULK_EMAIL.HOME} element={<SendBulkEmailScreen />} />
         <Route path={APP_URLS.COMMUNICATION_SEND_BULK_SMS.HOME} element={<SendBulkSMSScreen />} />
         <Route path={APP_URLS.COMMUNICATION_SEND_NOTIFICATIONS.HOME} element={<SendNotificationsScreen />} />
         <Route path={APP_URLS.COMMUNICATION_STAFF.HOME} element={<StaffScreen />} />
         {/* <Route path={APP_URLS.FINANCIAL_A_R.HOME} element={<CalendarS />} /> */}

         <Route path={APP_URLS.REPORTS_BRANCH_SUMMARY.HOME} element={<BranchSummaryScreen />} />
         <Route path={APP_URLS.REPORTS_DAILY_BOOKINGS_STATISTICS.HOME} element={<DailyBookingsStatisticsScreen />} />
         <Route path={APP_URLS.REPORTS_DAILY_DROPOFF_MANIFEST.HOME} element={<DailyDropoffManifestScreen />} />
         <Route path={APP_URLS.REPORTS_NO_SHOW_MANIFEST.HOME} element={<NoShowManifestScreen />} />
         <Route path={APP_URLS.REPORTS_PAY_ON_ARRIVAL_BOOKINGS.HOME} element={<PayOnArrivalBookingsScreen />} />

         <Route path={APP_URLS.MAINTENANCE_CATEGORYS.HOME} element={<MaintenanceCategorysScreen />} />
         <Route path={APP_URLS.MAINTENANCE_RECORDS.HOME} element={<MaintenanceRecordsScreen />} />
         
         <Route path={APP_URLS.CLAIMS_COST_CATEGORY.HOME} element={<ClaimsCostCategoryScreen />} />
         <Route path={APP_URLS.CAIMS.HOME} element={<ClaimsScreen />} />

         <Route path={APP_URLS.COUNTER_MANAGER_BODY_REPAIR_SHOPS.HOME} element={<BodyRepairShopsScreen />} />
         <Route path={APP_URLS.COUNTER_MANAGER_BROADCAST_MESSAGES.HOME} element={<BroadcastMessagesScreen />} />
         <Route path={APP_URLS.COUNTER_MANAGER_NON_REV_MOVEMENT.HOME} element={<NonRevMovementScreen />} />

         <Route path={APP_URLS.COUNTER_ACCOUNTS_RECEIVABLE_REPORT.HOME} element={<AccountsReceivableReportScreen />} />
         <Route path={APP_URLS.COUNTER_CIRCLE_SHEET_REPORT.HOME} element={<CircleSheetReportScreen />} />
         <Route path={APP_URLS.COUNTER_FLEET_DUE_BACK_REPORT.HOME} element={<FleetDueBackReportScreen />} />
         <Route path={APP_URLS.COUNTER_FLEET_LIST_REPORT.HOME} element={<FleetListReportScreen />} />
         <Route path={APP_URLS.COUNTER_FLEET_PERFORMANCE_REPORT.HOME} element={<FleetPerformanceReportScreen />} />
         <Route path={APP_URLS.COUNTER_NET_AVAILABILITY_REPORT.HOME} element={<NetAvailabilityReportScreen />} />
         <Route path={APP_URLS.COUNTER_PAYMENTS_REPORT.HOME} element={<PaymentsReportScreen />} />
         <Route path={APP_URLS.COUNTER_RENTAL_OVERDUE_REPORT.HOME} element={<RentalOverdueReportScreen />} />
         <Route path={APP_URLS.COUNTER_RESERVASTIONS_REPORT.HOME} element={<ReservationReportScreen />} />
         <Route path={APP_URLS.COUNTER_REVENUE_REPORT.HOME} element={<RevenueReportScreen />} />

         <Route path={APP_URLS.SYSTEM_USERS.HOME} element={<UserScreen />} />
         <Route path={APP_URLS.SYSTEM_USERS.EDIT} element={<CreateUsersContainer isEdit={true} />} />


        
         <Route path="*" element={ <NotFound />} />
    </Routes>
  )
}
