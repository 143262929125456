import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  buttonmargin: {
    "& button": {
      marginLeft: "10px",
    },
  },
  bg: {
    background: theme.palette.background.bg,
    padding: "5px 15px",
  },
  mainstep: {
    marginBottom: "40px",
  },
  pt0: {
    paddingTop: "0px !important",
  },
}));
