import React from "react";
import SendBulkEmail from "../../../component/Communication/SendBulkEmail";

const SendBulkEmailScreen = () => {
  return (
    <div>
      <SendBulkEmail />
    </div>
  );
};

export default SendBulkEmailScreen;
