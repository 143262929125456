import React from "react";
import TaskManagementSystem from "../../../component/TaskAssignment/TaskManagementSystem";

const TaskManagementSystemScreen = () => {
  return (
    <div>
      <TaskManagementSystem />
    </div>
  );
};

export default TaskManagementSystemScreen;
