import React from "react";
import ReservationsQuotes from "../../../component/Reservations/ReservationsQuotes";

const ReservationsQuotesScreen = () => {
  return (
    <div>
      <ReservationsQuotes />
    </div>
  );
};

export default ReservationsQuotesScreen;
