import { ProgressBar } from "primereact/progressbar";
import { useParams } from "react-router-dom";
import CreateUsersComponent from "../../../../component/System/Users/CreateUsers";

const CreateUsersContainer = (props) => {
  const { id } = useParams();

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };

  const createUsersProps = {};

  return (
    <>
      <CreateUsersComponent {...createUsersProps} />;
    </>
  );
};

export default CreateUsersContainer;
