import React from "react";
import { TableRow, TableCell, Checkbox } from "@mui/material";

const UserAccessSubRow = ({ sub, handleChange }) => {
  return (
    <TableRow>
      <TableCell sx={{ pl: 5, py: 0, marginLeft: '2px' }}>{sub.name}</TableCell>
      <TableCell sx={{ padding: 0, marginLeft: '2px' }}>
        <Checkbox
          checked={sub.view}
          onChange={() => handleChange(sub.id, "view")}
          style={{color:"blue"}}
        />
      </TableCell>
      <TableCell sx={{ padding: 0, marginLeft: '2px' }}>
        <Checkbox
          checked={sub.create}
          onChange={() => handleChange(sub.id, "create")}
          style={{color:"blue"}}
        />
      </TableCell>
      <TableCell sx={{ padding: 0, marginLeft: '2px' }}>
        <Checkbox
          checked={sub.edit}
          onChange={() => handleChange(sub.id, "edit")}
          style={{color:"blue"}}
        />
      </TableCell>
    </TableRow>
  );
};

export default UserAccessSubRow;
