import React from "react";
import DailyDropoffManifest from "../../../component/Reports/DailyDropoffManifest";

const DailyDropoffManifestScreen = () => {
  return (
    <div>
      <DailyDropoffManifest />
    </div>
  );
};

export default DailyDropoffManifestScreen;
