import React, { useState } from 'react'
import { Box, Container, Stack, Typography } from '@mui/material';
import { Link } from "react-router-dom"
import styleCss from './style.module.css';
import Logoicon from '../../../images/logo.png';
import ThemeToggle from '../../ThemeProvider/ToggleTheme';
import { useStyles } from './style';
import { APP_URLS } from '../../../utils/constant';
import { removeWordAfterAt } from '../../../utils';

export default function Header(props) {
    const classes = useStyles()
    const handleLogout = () => {
        localStorage.clear();
        window.location.replace('/');
    }
    const [isMenu, setIsMenu] = useState(false);
    const handleToggle = () => {
        setIsMenu(!isMenu)
    };
    return (
        <div>
            <Box className={classes.topnav}>
                <Container maxWidth="" className={styleCss.header}>
                    <Stack   direction="row" justifyContent="space-between" bgcolor="#2456C9" alignItems="center">
                        <Box className={styleCss.logobox}>
                            <img className={styleCss.logo} src={Logoicon} alt="log" />
                        </Box>
                        <Box display="flex" >
                            <Stack direction="row" px={1} alignItems="center"  >


                                <Box className={classes.logout} display="flex" m={0.5} >
                                    <Typography>  {removeWordAfterAt(localStorage.getItem("username"))}</Typography>
                                    <ul>
                                        <li><Link to={APP_URLS.CHANGEPASSWORD}>Profile</Link> </li>
                                        <li onClick={handleLogout}>Logout</li>
                                    </ul>



                                </Box>

                                {/* <ThemeToggle /> */}


                            </Stack>
                        </Box>
                    </Stack>
                </Container>
            </Box>
        </div>
    )
}
