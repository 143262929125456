import { useEffect } from "react";
import companyData from "./tableData.json";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fetchReservationsBookingsbyLocationReport,
  initializeReservationsBookingsbyLocationReportScreen,
  setDeleteConfirmationPopupVisibility,
  exportReservationsBookingsbyLocationReport,
} from "../../../redux/actions/Reservations/ReservationsBookingsbyLocationReport";
import { fetchLocationDD } from "../../../redux/actions/location";
import { fetchRateAdminNetworkDD } from "../../../redux/actions/RateAdmin/rateadminRates";
import ReservationsBookingsbyLocationReport from "../../../component/Reservations/ReservationsBookingsbyLocationReport";
import { generatePath } from "react-router-dom";
import { loadingShow } from "../../../services/PostAPI";
import { APP_URLS } from "../../../utils/constant";
import { setIsSearch } from "../../../redux/actions/common/search";

function ReservationsBookingsbyLocationReportScreen(props) {
  const {
    fetchReservationsBookingsbyLocationReport,
    initReservationsBookingsbyLocationReportScreen,
    showPageLevelLoader,
    ReservationsBookingsbyLocationReportListview,
    paginationInfo,
    exportReservationsBookingsbyLocationReport,
    isReservationsBookingsbyLocationReportExportError,
    isReservationsBookingsbyLocationReportExportSuccess,
    isLoading,
    fetchLocationDD,
    fetchRateAdminNetworkDD,
    RateNetworkDD,
    locationDD,
    setIsSearch,
    isSearch,
  } = props;
  const navigate = useNavigate();

  const callAPIasync = async () => {
    await fetchRateAdminNetworkDD();
    await fetchLocationDD();
  };

  useEffect(() => {
    callAPIasync();
  }, []);

  useEffect(() => {
    initReservationsBookingsbyLocationReportScreen();
    fetchReservationsBookingsbyLocationReport({
      sortField: "id",
      userId: localStorage.getItem("userId"),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadLazyData = (props) => {
    let newProps = {
      ...props,
      userId: localStorage.getItem("userId"),
      isSearch
    };
    fetchReservationsBookingsbyLocationReport(newProps);
  };
  const handleCreateReservationsBookingsbyLocationReport = () => {
    navigate(APP_URLS.RESERVATIONS_BOOKINGBYLOCATIONREPORT.CREATE);
  };
  const editReservationsBookingsbyLocationReportRoute = (id) => {
    const path = generatePath(
      APP_URLS.RESERVATIONS_BOOKINGBYLOCATIONREPORT.EDIT,
      {
        id,
      }
    );
    navigate(path);
  };
  const ReservationsBookingsbyLocationReportProps = {
    handleCreateReservationsBookingsbyLocationReport,
    exportReservationsBookingsbyLocationReport,
    isReservationsBookingsbyLocationReportExportError,
    isReservationsBookingsbyLocationReportExportSuccess,
    editReservationsBookingsbyLocationReportRoute,
    ...companyData,
    screenPermissions: companyData?.screenPermissions,
    setDeleteConfirmationPopupVisibility,
    fetchReservationsBookingsbyLocationReports: loadLazyData,
    ...companyData,
    tableData: {
      columns: ReservationsBookingsbyLocationReportListview?.columns,
      rows: ReservationsBookingsbyLocationReportListview?.rows,
    },
    paginationInfo: {
      pageSize: paginationInfo?.pageSize,
      pageNumber: paginationInfo?.pageNumber,
      totalPages: paginationInfo?.totalPages,
      totalRecords: paginationInfo?.totalRecords,
    },
    isLoading,
    RateNetworkDD,
    locationDD,
    setIsSearch
  };

  if (showPageLevelLoader) {
    loadingShow("block");
    return null;
  }
  loadingShow("none");
  return (
    <div>
      <ReservationsBookingsbyLocationReport
        {...ReservationsBookingsbyLocationReportProps}
      />
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    initReservationsBookingsbyLocationReportScreen: () =>
      dispatch(initializeReservationsBookingsbyLocationReportScreen()),
    fetchReservationsBookingsbyLocationReport: (payload) =>
      dispatch(fetchReservationsBookingsbyLocationReport(payload)),
    setDeleteConfirmationPopupVisibility: (payload) =>
      dispatch(setDeleteConfirmationPopupVisibility(payload)),
    fetchRateAdminNetworkDD: (payload) =>
      dispatch(fetchRateAdminNetworkDD(payload)),
    fetchLocationDD: (payload) => dispatch(fetchLocationDD(payload)),
    exportReservationsBookingsbyLocationReport: (payload) =>
      dispatch(exportReservationsBookingsbyLocationReport(payload)),
    setIsSearch: (payload) => dispatch(setIsSearch(payload)),
  };
};

//vehicleCagegoryReducer
const mapStateToProps = (state, ownProps) => {
  return {
    isSearch: selectFromReservationsBookingsbyLocationReportReducer(
      state,
      "isSearch"
    ),
    isReservationsBookingsbyLocationReportCreateSuccess:
      selectFromReservationsBookingsbyLocationReportReducer(
        state,
        "isReservationsBookingsbyLocationReportCreateSuccess"
      ),
    ReservationsBookingsbyLocationReportListview:
      selectFromReservationsBookingsbyLocationReportReducer(
        state,
        "ReservationsBookingsbyLocationReportListview"
      ),
    showPageLevelLoader: selectFromReservationsBookingsbyLocationReportReducer(
      state,
      "showPageLevelLoader"
    ),
    paginationInfo: selectFromReservationsBookingsbyLocationReportReducer(
      state,
      "paginationInfo"
    ),
    isPageLevelError: selectFromReservationsBookingsbyLocationReportReducer(
      state,
      "isPageLevelError"
    ),
    isFetchingGridData: selectFromReservationsBookingsbyLocationReportReducer(
      state,
      "isFetchingGridData"
    ),
    RateNetworkDD: selectFromRateAdminRatesReducer(state, "RateNetworkDD"),
    locationDD: selectFromLocationReducer(state, "locationDD"),
    isReservationsBookingsbyLocationReportExportError:
      selectFromReservationsBookingsbyLocationReportReducer(
        state,
        "isReservationsBookingsbyLocationReportExportError"
      ),
    isReservationsBookingsbyLocationReportExportSuccess:
      selectFromReservationsBookingsbyLocationReportReducer(
        state,
        "isReservationsBookingsbyLocationReportExportSuccess"
      ),
    ...ownProps,
  };
};

const selectFromReservationsBookingsbyLocationReportReducer = (state, path) => {
  return selectReservationsBookingsbyLocationReportReducer(state)[path];
};

const selectReservationsBookingsbyLocationReportReducer = ({
  ReservationsBookingsbyLocationReportReducer,
}) => {
  return ReservationsBookingsbyLocationReportReducer;
};

// For Network
const selectFromRateAdminRatesReducer = (state, path) => {
  return selectRateAdminRatesReducer(state)[path];
};

const selectRateAdminRatesReducer = ({ rateadminRatesReducer }) => {
  return rateadminRatesReducer;
};
//Location
const selectFromLocationReducer = (state, path) => {
  return selectLocationReducer(state)[path];
};
const selectLocationReducer = ({ locationReducer }) => {
  return locationReducer;
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReservationsBookingsbyLocationReportScreen);
