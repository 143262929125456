import React from "react";
import TrafficLogs from "../../../component/Website/TrafficLog";

const TrafficLogsScreen = () => {
  return (
    <div>
      <TrafficLogs />
    </div>
  );
};

export default TrafficLogsScreen;
