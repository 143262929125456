import React from "react";
import MaintenanceCategorys from "../../../component/Maintenance/MaintenanceCategorys";

const MaintenanceCategorysScreen = () => {
  return (
    <div>
      <MaintenanceCategorys />
    </div>
  );
};

export default MaintenanceCategorysScreen;
