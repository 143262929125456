import React from "react";
import Comingsoonpage from "../../../common/Comingsoon";

const Newsletters = () => {
  return (
    <div>
      <Comingsoonpage />
    </div>
  );
};

export default Newsletters;
