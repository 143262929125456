import React from "react";
import RevenueReport from "../../../component/CounterReports/RevenueReport";

const RevenueReportScreen = () => {
  return (
    <div>
      <RevenueReport />
    </div>
  );
};

export default RevenueReportScreen;
