import { DEFAULT_TOAST_LIFE } from "../../../../common/EzToast";
 import { ReservationsBookingsbyLocationReportServices } from "../../../../services";
import { goBack } from "../../../../utils/gotoback";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_RESERVATIONS_BOOKINGBYLOCATIONREPORT_INIT, 
  FETCH_RESERVATIONS_BOOKINGBYLOCATIONREPORT_LIST, 
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY, 
  INIT_CREATE_RESERVATIONS_BOOKINGBYLOCATIONREPORT_SCREEN,
  CREATE_RESERVATIONS_BOOKINGBYLOCATIONREPORT,
  EDIT_RESERVATIONS_BOOKINGBYLOCATIONREPORT,
  TOAST,
  FETCH_RESERVATIONS_BOOKINGBYLOCATIONREPORT_DETAIL,
  EXPORT_RESERVATIONS_BOOKINGBYLOCATIONREPORT,
  RESERVATIONS_BOOKINGBYLOCATIONREPORTDD,
  RESERVATIONS_BOOKINGBYLOCATIONREPORTDELETE
} from "../../../types/Reservations/BookingsbyLocationReport.type";
export const initializeReservationsBookingsbyLocationReportScreen = () => ({ type: FETCH_RESERVATIONS_BOOKINGBYLOCATIONREPORT_INIT });

export const ReservationsBookingsbyLocationReportFetchStart = (payload) => ({
  type: FETCH_RESERVATIONS_BOOKINGBYLOCATIONREPORT_LIST.START,
  payload,
});

export const ReservationsBookingsbyLocationReportFetchSuccess = (payload) => ({
  type:FETCH_RESERVATIONS_BOOKINGBYLOCATIONREPORT_LIST.SUCCESS,
  payload,
});

export const ReservationsBookingsbyLocationReportFetchError = (payload) => ({
  type: FETCH_RESERVATIONS_BOOKINGBYLOCATIONREPORT_LIST.ERROR,
  payload,
});

export const fetchReservationsBookingsbyLocationReport = (payload) => {
  console.log("Component mounted");
  return (dispatch) => {
    dispatch(ReservationsBookingsbyLocationReportFetchStart(payload));
    ReservationsBookingsbyLocationReportServices.fetchReservationsBookingsbyLocationReport(payload).then((data) => {
      if (!data.isError) {
        dispatch(ReservationsBookingsbyLocationReportFetchSuccess(data));
      } else {
        dispatch(ReservationsBookingsbyLocationReportFetchError(data));
      }
    });
  };
};



// DD
export const ReservationsBookingsbyLocationReportDDFetchStart = (payload) => ({
  type: RESERVATIONS_BOOKINGBYLOCATIONREPORTDD.START,
  payload,
});

export const ReservationsBookingsbyLocationReportDDFetchSuccess = (payload) => ({
  type:RESERVATIONS_BOOKINGBYLOCATIONREPORTDD.SUCCESS,
  payload,
});

export const ReservationsBookingsbyLocationReportDDFetchError = (payload) => ({
  type: RESERVATIONS_BOOKINGBYLOCATIONREPORTDD.ERROR,
  payload,
});

export const fetchReservationsBookingsbyLocationReportDD = (payload) => {
  return (dispatch) => {
    dispatch(ReservationsBookingsbyLocationReportDDFetchStart(payload));
    ReservationsBookingsbyLocationReportServices.fetchReservationsBookingsbyLocationReportDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(ReservationsBookingsbyLocationReportDDFetchSuccess(data));
      } else {
        dispatch(ReservationsBookingsbyLocationReportDDFetchError(data));
      }
    });
  };
};

//END DD

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const ReservationsBookingsbyLocationReportDetailFetchStart = (payload) => ({
  type: FETCH_RESERVATIONS_BOOKINGBYLOCATIONREPORT_DETAIL.START,
  payload,
});

export const ReservationsBookingsbyLocationReportDetailFetchSuccess = (payload) => ({
  type: FETCH_RESERVATIONS_BOOKINGBYLOCATIONREPORT_DETAIL.SUCCESS,
  payload,
});

export const ReservationsBookingsbyLocationReportDetailFetchError = (payload) => ({
  type: FETCH_RESERVATIONS_BOOKINGBYLOCATIONREPORT_DETAIL.ERROR,
  payload,
});

export const fetchReservationsBookingsbyLocationReportById = (payload) => {
  return (dispatch) => {
    dispatch(ReservationsBookingsbyLocationReportDetailFetchStart(payload));

    ReservationsBookingsbyLocationReportServices
      .fetchReservationsBookingsbyLocationReportById(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(ReservationsBookingsbyLocationReportDetailFetchSuccess(data));
        } else {
          dispatch(ReservationsBookingsbyLocationReportDetailFetchError(data));
        }
      });
  };
};

export const uploadImageStart = () => ({
  type: UPLOAD_IMAGE.START,
});

export const uploadImageSuccess = (payload) => ({
  type: UPLOAD_IMAGE.SUCCESS,
  payload,
});

export const uploadImageError = (payload) => ({
  type: UPLOAD_IMAGE.ERROR,
  payload,
});

export const uploadImage = (payload) => {
  return (dispatch) => {
    dispatch(uploadImageStart());
    ReservationsBookingsbyLocationReportServices.upladImageData(payload).then((data) => {
      if (!data.isError) {
        dispatch(uploadImageSuccess(data));
      } else {
        dispatch(uploadImageError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
/*---------------End Upload Image------------------------*/
export const createReservationsBookingsbyLocationReportScreenInitStart = (payload) => ({
  type: INIT_CREATE_RESERVATIONS_BOOKINGBYLOCATIONREPORT_SCREEN,
  payload,
});

export const initCreateReservationsBookingsbyLocationReportScreen = (payload) => {
  return (dispatch) => {
    dispatch(createReservationsBookingsbyLocationReportScreenInitStart());
  };
};


export const createReservationsBookingsbyLocationReportStart = () => ({
  type: CREATE_RESERVATIONS_BOOKINGBYLOCATIONREPORT.START,
});

export const createReservationsBookingsbyLocationReportSuccess = (payload) => ({
  type: CREATE_RESERVATIONS_BOOKINGBYLOCATIONREPORT.SUCCESS,
  payload,
});

export const createReservationsBookingsbyLocationReportError = (payload) => ({
  type: CREATE_RESERVATIONS_BOOKINGBYLOCATIONREPORT.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createReservationsBookingsbyLocationReport = (payload) => {
  return (dispatch) => {
    dispatch(createReservationsBookingsbyLocationReportStart());
    ReservationsBookingsbyLocationReportServices.createReservationsBookingsbyLocationReport(payload).then((data) => {
      if (!data.isError) {
        dispatch(createReservationsBookingsbyLocationReportSuccess());
        goBack();
      } else {
        dispatch(createReservationsBookingsbyLocationReportError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editReservationsBookingsbyLocationReportStart = (payload) => ({
  type: EDIT_RESERVATIONS_BOOKINGBYLOCATIONREPORT.START,
  payload
});

export const editReservationsBookingsbyLocationReportSuccess = (payload) => ({
  type: EDIT_RESERVATIONS_BOOKINGBYLOCATIONREPORT.SUCCESS,
  payload,
});

export const editReservationsBookingsbyLocationReportError = (payload) => ({
  type: EDIT_RESERVATIONS_BOOKINGBYLOCATIONREPORT.ERROR,
  payload,
});

export const editReservationsBookingsbyLocationReport = (payload) => {
  return (dispatch) => {
    dispatch(editReservationsBookingsbyLocationReportStart());
    ReservationsBookingsbyLocationReportServices.editReservationsBookingsbyLocationReport(payload).then((data) => {
      if (!data.isError) {
        dispatch(editReservationsBookingsbyLocationReportSuccess());
        goBack();
      } else {
        dispatch(editReservationsBookingsbyLocationReportError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const exportReservationsBookingsbyLocationReportRecordStart = (payload) => ({
  type: EXPORT_RESERVATIONS_BOOKINGBYLOCATIONREPORT.START,
  payload
});

export const exportReservationsBookingsbyLocationReportRecordSuccess = (payload) => ({
  type: EXPORT_RESERVATIONS_BOOKINGBYLOCATIONREPORT.SUCCESS,
  payload,
});

export const exportReservationsBookingsbyLocationReportRecordError = (payload) => ({
  type: EXPORT_RESERVATIONS_BOOKINGBYLOCATIONREPORT.ERROR,
  payload,
});

export const exportReservationsBookingsbyLocationReport = (payload) => {
  return (dispatch) => {
    dispatch(exportReservationsBookingsbyLocationReportRecordStart());
    ReservationsBookingsbyLocationReportServices.exportReservationsBookingsbyLocationReportRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportReservationsBookingsbyLocationReportRecordSuccess(data));
      } else {
        dispatch(exportReservationsBookingsbyLocationReportRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};


/*---------------START RATE MIRRORS DELETE------------------*/

export const ReservationsBookingsbyLocationReportDeleteStart = (payload) => ({
  type: RESERVATIONS_BOOKINGBYLOCATIONREPORTDELETE.START,
  payload,
});

export const ReservationsBookingsbyLocationReportDeleteSuccess = (payload) => ({
  type: RESERVATIONS_BOOKINGBYLOCATIONREPORTDELETE.SUCCESS,
  payload,
});

export const ReservationsBookingsbyLocationReportDeleteError = (payload) => ({
  type: RESERVATIONS_BOOKINGBYLOCATIONREPORTDELETE.ERROR,
  payload,
});

export const deleteReservationsBookingsbyLocationReport = (payload) => {
  return (dispatch) => {
    dispatch(ReservationsBookingsbyLocationReportDeleteStart(payload));

    ReservationsBookingsbyLocationReportServices
      .deleteReservationsBookingsbyLocationReport(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(ReservationsBookingsbyLocationReportDeleteSuccess(data));
          dispatch(fetchReservationsBookingsbyLocationReport(payload?.params));
        } else {
          dispatch(ReservationsBookingsbyLocationReportDeleteError(data));
        }
      });
  };
};

/*----------------END----------------------------*/