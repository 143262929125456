import React from "react";
import NonRevMovement from "../../../component/CounterManager/NonRevMovement";

const NonRevMovementScreen = () => {
  return (
    <div>
      <NonRevMovement />
    </div>
  );
};

export default NonRevMovementScreen;
