import React from "react";
import Comingsoonpage from "../../../common/Comingsoon";

const RevenueReport = () => {
  return (
    <div>
      <Comingsoonpage />
    </div>
  );
};

export default RevenueReport;
