import React from "react";
import MiscUploads from "../../../component/Financial/MiscUploads";

const MiscUploadsScreen = () => {
  return (
    <div>
      <MiscUploads />
    </div>
  );
};

export default MiscUploadsScreen;
