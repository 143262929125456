import {
  arrayBufferDownload,
  arrayBufferDownloadExcel,
  downloadCSV,
} from "../../../../utils/download";
import {
  decorateEmptyGridValues,
  setColumnDefaultExtraProps,
} from "../../../../utils/table/gridUtils";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_VEHICLE_TRIM_LIST,
  TOAST,
  FETCH_VEHICLE_TRIM_INIT,
  FETCH_VEHICLE_TRIM_DETAIL,
  EXPORT_VEHICLE_TRIM,
  CREATE_VEHICLE_TRIM,
  EDIT_VEHICLE_TRIM,
  INIT_CREATE_VEHICLE_TRIM_SCREEN,
  VEHICLE_TRIMDD,
} from "../../../types/vehicletrim.type";

const formFieldValueMap = {
  id: null,
  makeId: "",
  modBy: "",
  modTime: "",
  status: 1,
  title: "",
  userId: "",
};

const INITIAL_STATE = {
  vehicleTrimListview: [],
  vehicleTrimDD: [],
  isVehicleTrimDDSuccess: false,
  isVehicleTrimDDError: false,
  isLoading: false,
  error: null,
  formFieldValueMap,
  isVehicleTrimCreateSuccess: false,
  isVehicleTrimCreateError: false,
  isVehicleTrimDetailSuccess: false,
  isVehicleTrimDetailError: false,
  isVehicleTrimEditSuccess: false,
  isVehicleTrimEditError: false,
  isVehicleTrimExportSuccess: false,
  isVehicleTrimExportError: false,
  isUploadImageSuccess: false,
  isUploadImageError: false,
  imageUploadResponse: null,
};

const tranformToGridView = (data) => {
  const rows = data.map((item) => {
    const {
      crtBy,
      crtTime,
      description,
      id,
      modelTitle,
      modBy,
      modTime,
      status,
      title,
    } = item;

    const transformedValues = {
      crtBy,
      crtTime,
      description,
      id,
      modelTitle,
      modBy,
      modTime,
      status,
      title,
    };
    const decoratedValues = {};
    Object.keys(transformedValues).forEach((key) => {
      decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
    });
    return decoratedValues;
  });

  const columns = [
    {
      colLabel: "ID",
      dataKey: "id",
      extraProps: {
        isSortable: true,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },
    {
      colLabel: "Trim",
      dataKey: "title",
      extraProps: {
        isSortable: true,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },
    {
      colLabel: "Description",
      dataKey: "description",
      extraProps: {
        isSortable: true,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },

    {
      colLabel: "Model",
      dataKey: "modelTitle",
      extraProps: {
        isSortable: true,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },
    {
      colLabel: "Crt By",
      dataKey: "crtBy",
      extraProps: {
        isSortable: true,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },
    {
      colLabel: "Crt Time",
      dataKey: "crtTime",
      extraProps: {
        isSortable: true,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },
    {
      colLabel: "Mod By",
      dataKey: "modBy",
      extraProps: {
        isSortable: true,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },
    {
      colLabel: "Edit Time",
      dataKey: "modTime",
      extraProps: {
        isSortable: true,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },
    {
      colLabel: "Status",
      dataKey: "status",
      extraProps: {
        isSortable: true,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },
  ];

  return {
    datas: { columns, rows },
  };
};

export const vehicleTrimReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_VEHICLE_TRIM_INIT:
      return { ...INITIAL_STATE, isLoadingPage: true };
    case FETCH_VEHICLE_TRIM_DETAIL.START:
    case EXPORT_VEHICLE_TRIM.START:
    case UPLOAD_IMAGE.START:
    case FETCH_VEHICLE_TRIM_LIST.START:
    case CREATE_VEHICLE_TRIM.START:
    case EDIT_VEHICLE_TRIM.START:
    case VEHICLE_TRIMDD.START:
      return { ...state, isLoading: true };

    case FETCH_VEHICLE_TRIM_LIST.SUCCESS: {
      const { page, data } = action.payload;

      let resetState = {
        vehicleTrimListview: [],
        paginationInfo: null,
        error: null,
        isLoading: false,
        isLoadingPage: false,
        isPageLevelError: false,
        isVehicleTrimCreateSuccess: false,
        isVehicleTrimCreateError: false,
      };
      if (!data) {
        return resetState;
      }
      const { number, size, totalElements, totalPages } = page;
      const { datas } = tranformToGridView(data);
      return {
        ...resetState,
        vehicleTrimListview: datas,
        paginationInfo: {
          pageSize: size,
          totalRecords: totalElements,
          totalPages: totalPages,
          pageNumber: number,
        },
      };
    }
    case FETCH_VEHICLE_TRIM_LIST.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        vehicleTrimListview: [],
        isLoading: false,
        isLoadingPage: false,
        error,
        isPageLevelError: true,
        isvehicleTrimDeletionSuccess: false,
        isvehicleTrimDeletionError: false,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }

    case FETCH_VEHICLE_TRIM_DETAIL.SUCCESS: {
      const temp = action.payload;
      if (action.payload && typeof action.payload === "object") {
        Object.keys(action.payload).forEach((key) => {
          if (formFieldValueMap.hasOwnProperty(key)) {
            temp[key] = action.payload[key];
          }
        });
      }

      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isVehicleTrimDetailSuccess: true,
      };
    }
    case FETCH_VEHICLE_TRIM_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isVehicleTrimDetailSuccess: false,
        isVehicleTrimDetailError: true,
      };
    }

    //DD
    case VEHICLE_TRIMDD.SUCCESS: {
      const temp = action.payload;

      return {
        ...state,
        isLoading: false,
        vehicleTrimDD: temp,
        isVehicleTrimDDSuccess: true,
        isVehicleTrimDDError: false,
      };
    }
    case VEHICLE_TRIMDD.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isVehicleTrimDDSuccess: false,
        isVehicleTrimDDError: true,
      };
    }
    //END DD

    // UPLOAD_IMAGE Start
    case UPLOAD_IMAGE.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        isLoading: false,
        isLoadingPage: false,
        error,
        isUploadImageSuccess: false,
        isUploadImageError: true,
        shouldOpenExportConfirmationPopup: false,
      };
    }

    case UPLOAD_IMAGE.SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        imageUploadResponse: data,
        isUploadImageSuccess: true,
        isUploadImageError: false,
        shouldOpenExportConfirmationPopup: false,
      };
    }

    // UPLOAD_IMAGE End

    // Export Start
    case EXPORT_VEHICLE_TRIM.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        isLoading: false,
        isLoadingPage: false,
        error,
        isVehicleTrimExportSuccess: false,
        isVehicleTrimExportError: true,
        shouldOpenExportConfirmationPopup: false,
      };
    }

    case EXPORT_VEHICLE_TRIM.SUCCESS: {
      const data = action.payload;
      arrayBufferDownloadExcel(data, "vehicle-make.xls");
      return {
        ...state,
        isVehicleTrimExportSuccess: true,
        isVehicleTrimExportError: false,
        shouldOpenExportConfirmationPopup: false,
      };
    }

    // Export End

    // EDIT
    case EDIT_VEHICLE_TRIM.ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isLoading: false,
        isVehicleTrimEditSuccess: false,
        isVehicleTrimEditError: true,
      };
    }

    case EDIT_VEHICLE_TRIM.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isVehicleTrimEditSuccess: true,
        isVehicleTrimEditError: false,
      };
    }

    // EDIT END

    // Create
    case INIT_CREATE_VEHICLE_TRIM_SCREEN: {
      return { ...INITIAL_STATE };
    }

    case CREATE_VEHICLE_TRIM.ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isLoading: false,
        isVehicleTrimCreateSuccess: false,
        isVehicleTrimCreateError: true,
      };
    }
    case CREATE_VEHICLE_TRIM.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isVehicleTrimCreateSuccess: true,
        isVehicleTrimCreateError: false,
      };
    }

    //End

    case TOAST.RESET: {
      return {
        ...state,
        isVehicleTrimCreateSuccess: false,
        isVehicleTrimCreateError: false,
        isVehicleTrimEditError: false,
        isVehicleTrimEditSuccess: false,
        isVehicleTrimDetailError: false,
      };
    }
    default:
      return state;
  }
};
