import { Box, Container } from "@mui/material";
import { UsersBreadcrumb } from "./constant";
import { EzToast, TOAST_SEVERITY } from "../../../common/EzToast";
import Breadcrumb from "../../../common/Breadcrumb";
import { useStyles } from "./style";
import { Button } from "primereact/button";
import { useRef, useState } from "react";
import EzNotFound from "../../../common/EzNotFound";
import EzTable from "../../../common/EzTable";

const getIconPath = (imgPathDelta = "/no-record.png") =>
  process.env.PUBLIC_URL + imgPathDelta;
const Users = (props) => {
  const classes = useStyles();
  const {
    tableData,
    paginationInfo,
    screenPermissions,
    isPageLevelError,
    fetchUsersInfos,
    setDeleteConfirmationPopupVisibility,
    handleEditUsers,
  } = props;
  const tableRef = useRef();
  const [isCopy, setIsCopy] = useState(false);

  const convertToTSV = (data) => {
    const headers = data.columns.map((item) => item.colLabel).join("\t");
    //const headers = Object.keys(data.rows[0]).join('\t');
    const rows = data.rows
      .map((row) => Object.values(row).join("\t"))
      .join("\n");
    return `${headers}\n${rows}`;
  };

  const copyToClipboard = () => {
    const csv = convertToTSV(tableData);
    navigator.clipboard
      .writeText(csv)
      .then(() => setIsCopy(true))
      .catch((err) => setIsCopy(true));
  };

  const exportSystemUsers = () => {};

  const exportCSVSystemUsers = () => {};

  const exportPDFSystemUsers = () => {};

  const handlePrint = () => {};

  const renderPageHeaderSection = () => {
    return (
      <div className="make-relative sub-header ">
        <div className="maintitlediv">
          <div className="ml-3">
            <h4 className="pagetitle">Users</h4>
          </div>
          <div className="buttonarea">
            {/* <Button
             label={areFiltersVisible ? "Advanced Filter" : "Advanced Filter"}
             icon={areFiltersVisible ? "pi pi-filter-slash" : "pi pi-filter"}
             className={`p-button-text p-button-secondary`}
             onClick={() => {
               toggleFilterState(!areFiltersVisible);
             }}
           /> */}
            <Button
              label={"Copy"}
              className={` p-button-raised`}
              style={{ borderRadius: "0px" }}
              onClick={copyToClipboard}
            />
            <Button
              label={"Excel"}
              className={` p-button-raised`}
              style={{ borderRadius: "0px" }}
              onClick={exportSystemUsers}
            />
            <Button
              label={"CSV"}
              className={` p-button-raised`}
              style={{ borderRadius: "0px" }}
              onClick={exportCSVSystemUsers}
            />
            <Button
              label={"PDF"}
              className={` p-button-raised`}
              style={{ borderRadius: "0px" }}
              onClick={exportPDFSystemUsers}
            />
            <Button
              label={"Print"}
              className={` p-button-raised`}
              style={{ borderRadius: "0px" }}
              onClick={handlePrint}
            />
            <Button
              label={"Create"}
              className={` p-button-raised`}
              style={{ borderRadius: "0px" }}
              onClick={handlePrint}
            />
          </div>
        </div>
      </div>
    );
  };

  const filters = {
    global: { value: null },
  };

  const getToastProps = () => {
    let toastTitle = "";
    if (isCopy) {
      let toastTitle = "Data copied to clipboard!";
      if (!isCopy) {
        toastTitle = "Error while Data copied to clipboard!";
      }

      return {
        severity: isCopy ? TOAST_SEVERITY.SUCCESS : TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const shouldRenderFullPageError = () => isPageLevelError;
  const shouldRenderUsersRecords = () => tableData?.columns?.length > 0;
  const shouldRenderNotFoundView = () =>
    !shouldRenderUsersRecords() && !shouldRenderFullPageError();

  const tableProps = {
    columns: tableData?.columns,
    paginationInfo,
    screenPermissions,
    value: tableData?.rows,
    emptyMessage: "No Users found.",
    loadLazyData: fetchUsersInfos,
    filters,
    loading: false,
    showMoreActions: false,
    sortField: null,
    onEditRecord: handleEditUsers,
    setDeleteConfirmationPopupVisibility,
  };

  return (
    <div>
      <Container maxWidth="lg">
        <Breadcrumb list={UsersBreadcrumb} />
        <EzToast {...getToastProps()} />
        <div className={`containerbox ${classes.bg}`}>
          {renderPageHeaderSection()}
          <div className="mTop15">
            {shouldRenderFullPageError() && (
              <EzNotFound
                messageStyle={{ marginLeft: "-70px" }}
                bgImgUrl={getIconPath("something-broke.png")}
                message={"Oops! Something broke, we are working on a fix."}
              />
            )}
            {shouldRenderUsersRecords() && (
              <Box ref={tableRef} id="printable">
                <EzTable {...tableProps} />
              </Box>
            )}
            {shouldRenderNotFoundView() && (
              <EzNotFound
                bgImgUrl={getIconPath()}
                message={"No Renters Info records to show"}
              />
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Users;
