import React from "react";
import FleetDueBackReport from "../../../component/CounterReports/FleetDueBackReport";

const FleetDueBackReportScreen = () => {
  return (
    <div>
      <FleetDueBackReport />
    </div>
  );
};

export default FleetDueBackReportScreen;
