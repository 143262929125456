import { createTheme } from '@mui/material/styles';

// 'light' theme
const light = createTheme({
	palette: {
		primary: {
			main: '#eef5f9',
			contrastText: '#caffbf'
		},
		background: {
			default: '#caffbf',
			// topheaderbg:"#2456C9",
			menubg:"#fff",
			menuhoverbg:"#ededed",
			bluebg:"#1b75d0",
			bg:"#ffffff"
		},
		text: {
			primary: '#25439a',
			black:"#000000",
			topheadertext:"#ffffff",
			menutext:"#808081",
			menuhovertxt:"#000",
			kpititle:"#414141",
			link:"#1769aa"
		}
	}
});

export default light;