import { API_URL } from "../../../../utils/constant";
import { APIError, handleAPIError } from "../../../common/errorHandler";
import { getDecoratedUrl } from "../../../common/urlService";
import { getApiAsyn } from "../../../PostAPI";

export const fetchUser = async (payload) => {
  const url = getDecoratedUrl({
    url: API_URL.SYSTEM_USERS.GET,
    payload,
  });
  try {
    let result = await getApiAsyn(url, localStorage.getItem("token"));

    if (result?.error) {
      throw new APIError(result);
    }
    return result || [];
    // return extractData(result, !payload?.filters);
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};
