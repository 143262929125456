import React from "react";
import FleetListReport from "../../../component/CounterReports/FleetListReport";

const FleetListReportScreen = () => {
  return (
    <div>
      <FleetListReport />
    </div>
  );
};

export default FleetListReportScreen;
