import React from "react";
import ReservationReport from "../../../component/CounterReports/ReservationReport";

const ReservationReportScreen = () => {
  return (
    <div>
      <ReservationReport />
    </div>
  );
};

export default ReservationReportScreen;
