import React, { useEffect, useState } from "react";
import companyData from "./tableData.json";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fetchReservationsDailyManifestReport,
  initializeReservationsDailyManifestReportScreen,
  setDeleteConfirmationPopupVisibility,
  exportReservationsDailyManifestReport,
} from "../../../redux/actions/Reservations/ReservationsDailyManifestReport";
import { fetchLocationDD } from "../../../redux/actions/location";
import { fetchRateAdminNetworkDD } from "../../../redux/actions/RateAdmin/rateadminRates";
import ReservationsDailyManifestReport from "../../../component/Reservations/ReservationsDailyManifestReport";
import { generatePath } from "react-router-dom";
import { loadingShow } from "../../../services/PostAPI";
import { APP_URLS } from "../../../utils/constant";
import { ProgressBar } from "primereact/progressbar";
import { setIsSearch } from "../../../redux/actions/common/search";

function ReservationsDailyManifestReportScreen(props) {
  const {
    fetchReservationsDailyManifestReport,
    initReservationsDailyManifestReportScreen,
    showPageLevelLoader,
    ReservationsDailyManifestReportListview,
    paginationInfo,
    exportReservationsDailyManifestReport,
    isReservationsDailyManifestReportExportError,
    isReservationsDailyManifestReportExportSuccess,
    isLoading,
    fetchLocationDD,
    fetchRateAdminNetworkDD,
    RateNetworkDD,
    locationDD,
    isSearch,
    setIsSearch
  } = props;
  const navigate = useNavigate();

  const callAPIasync = async () => {
    await fetchRateAdminNetworkDD();
    await fetchLocationDD();
  };

  useEffect(() => {
    callAPIasync();
  }, []);
  useEffect(() => {
    initReservationsDailyManifestReportScreen();
    fetchReservationsDailyManifestReport({
      sortField: "id",
      userId: localStorage.getItem("userId"),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadLazyData = (props) => {
    let newProps = {
      ...props,
      userId: localStorage.getItem("userId"),
      isSearch,
    };
    fetchReservationsDailyManifestReport(newProps);
  };
  const handleCreateReservationsDailyManifestReport = () => {
    navigate(APP_URLS.RESERVATIONS_DAILYMANIFESTREPORT.CREATE);
  };
  const editReservationsDailyManifestReportRoute = (id) => {
    const path = generatePath(APP_URLS.RESERVATIONS_DAILYMANIFESTREPORT.EDIT, {
      id,
    });
    navigate(path);
  };
  const ReservationsDailyManifestReportProps = {
    handleCreateReservationsDailyManifestReport,
    exportReservationsDailyManifestReport,
    isReservationsDailyManifestReportExportError,
    isReservationsDailyManifestReportExportSuccess,
    editReservationsDailyManifestReportRoute,
    ...companyData,
    screenPermissions: companyData?.screenPermissions,
    setDeleteConfirmationPopupVisibility,
    fetchReservationsDailyManifestReports: loadLazyData,
    ...companyData,
    tableData: {
      columns: ReservationsDailyManifestReportListview?.columns,
      rows: ReservationsDailyManifestReportListview?.rows,
    },
    paginationInfo: {
      pageSize: paginationInfo?.pageSize,
      pageNumber: paginationInfo?.pageNumber,
      totalPages: paginationInfo?.totalPages,
      totalRecords: paginationInfo?.totalRecords,
    },
    isLoading,
    RateNetworkDD,
    locationDD,
    setIsSearch,
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };

  if (showPageLevelLoader) {
    loadingShow("block");
    return null;
  }
  loadingShow("none");
  return (
    <div>
      {isLoading && renderProgressBar()}
      <ReservationsDailyManifestReport
        {...ReservationsDailyManifestReportProps}
      />
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    setIsSearch: (payload) => dispatch(setIsSearch(payload)),
    initReservationsDailyManifestReportScreen: () =>
      dispatch(initializeReservationsDailyManifestReportScreen()),
    fetchReservationsDailyManifestReport: (payload) =>
      dispatch(fetchReservationsDailyManifestReport(payload)),
    setDeleteConfirmationPopupVisibility: (payload) =>
      dispatch(setDeleteConfirmationPopupVisibility(payload)),
    fetchRateAdminNetworkDD: (payload) =>
      dispatch(fetchRateAdminNetworkDD(payload)),
    fetchLocationDD: (payload) => dispatch(fetchLocationDD(payload)),
    exportReservationsDailyManifestReport: (payload) =>
      dispatch(exportReservationsDailyManifestReport(payload)),
  };
};

//vehicleCagegoryReducer
const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: selectFromReservationsDailyManifestReportReducer(
      state,
      "isLoading"
    ),
    isSearch: selectFromReservationsDailyManifestReportReducer(
      state,
      "isSearch"
    ),
    isReservationsDailyManifestReportCreateSuccess:
      selectFromReservationsDailyManifestReportReducer(
        state,
        "isReservationsDailyManifestReportCreateSuccess"
      ),
    ReservationsDailyManifestReportListview:
      selectFromReservationsDailyManifestReportReducer(
        state,
        "ReservationsDailyManifestReportListview"
      ),
    showPageLevelLoader: selectFromReservationsDailyManifestReportReducer(
      state,
      "showPageLevelLoader"
    ),
    paginationInfo: selectFromReservationsDailyManifestReportReducer(
      state,
      "paginationInfo"
    ),
    isPageLevelError: selectFromReservationsDailyManifestReportReducer(
      state,
      "isPageLevelError"
    ),
    isFetchingGridData: selectFromReservationsDailyManifestReportReducer(
      state,
      "isFetchingGridData"
    ),
    RateNetworkDD: selectFromRateAdminRatesReducer(state, "RateNetworkDD"),
    locationDD: selectFromLocationReducer(state, "locationDD"),
    isReservationsDailyManifestReportExportError:
      selectFromReservationsDailyManifestReportReducer(
        state,
        "isReservationsDailyManifestReportExportError"
      ),
    isReservationsDailyManifestReportExportSuccess:
      selectFromReservationsDailyManifestReportReducer(
        state,
        "isReservationsDailyManifestReportExportSuccess"
      ),
    ...ownProps,
  };
};

const selectFromReservationsDailyManifestReportReducer = (state, path) => {
  return selectReservationsDailyManifestReportReducer(state)[path];
};

const selectReservationsDailyManifestReportReducer = ({
  ReservationsDailyManifestReportReducer,
}) => {
  return ReservationsDailyManifestReportReducer;
};

// For Network
const selectFromRateAdminRatesReducer = (state, path) => {
  return selectRateAdminRatesReducer(state)[path];
};

const selectRateAdminRatesReducer = ({ rateadminRatesReducer }) => {
  return rateadminRatesReducer;
};
//Location
const selectFromLocationReducer = (state, path) => {
  return selectLocationReducer(state)[path];
};
const selectLocationReducer = ({ locationReducer }) => {
  return locationReducer;
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReservationsDailyManifestReportScreen);
