import React from "react";
import Ar from "../../../component/Financial/Ar";

const ArScreen = () => {
  return (
    <div>
      <Ar />
    </div>
  );
};

export default ArScreen;
