import React from "react";
import BodyRepairShops from "../../../component/CounterManager/BodyRepairShops";

const BodyRepairShopsScreen = () => {
  return (
    <div>
      <BodyRepairShops />
    </div>
  );
};

export default BodyRepairShopsScreen;
